import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import styles from './../../assets/css/dashboard.module.scss';
import mapa from './../../assets/images/mapa-guine-bissau.png';

import totalAgendamentos_img from './../../assets/icons/agendamentos-dia.png';
import totalAtendidos_img from './../../assets/icons/atendidos.png';
import totalPorAtender_img from './../../assets/icons/por-atender.png';
import totalCancelados_img from './../../assets/icons/cancelados.png';

import Progress from '../../components/ui/Progress';


import { Alert, ListItemText, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/ui/Loading';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';

export default function HomePage() {
    const [nAtendidos, setNAtendidos] = React.useState(0);
    const [nCancelados, setNCancelados] = React.useState(0);
    const [nPorAtender, setNPorAtender] = React.useState(0);
    const [nTotalAgendamentos, setNTotalAgendamentos] = React.useState(0);
  
    const [nAtendidosPerc, setNAtendidosPerc] = React.useState(0);
    const [nCanceladosPerc, setNCanceladosPerc] = React.useState(0);
    const [nPorAtenderPerc, setNPorAtenderPerc] = React.useState(0);
    const [nTotalAgendamentosPerc, setNTotalAgendamentosPerc] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();


    const axiosPrivate = useAxiosPrivate();

    const getPercentage = (value, min, max) => {
        if (value > 0) {
            return (((value - min) * 100) / (max - min)).toFixed(1).replace(/\.0$/, '');
        }
        return 0;
    }

    const [loading, setLoading] = useState(false);

    const margin = 9;

    const URL_SCHEDULINGS_STATISTICS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_SCHEDULINGS_STATISTICS}`;
    
    useEffect(() => {
        const fetchSchedulingData = async() => {

            try {
                const response = await axiosPrivate.get(URL_SCHEDULINGS_STATISTICS + '?v=' + new Date().valueOf());
                mapDashboardData(response);
                setLoading(false);
                
            } catch (error) {
                setLoading(false);
                 /*Expired token then force logout*/
                if (error.response.status === HttpResponseStatus.UNAUTHORIZED) {
                    localStorage.clear();
                    navigate('/login');
                    return;
                }
                console.log("Error: ", error);
            }
        }

        fetchSchedulingData();
        handleClick();
    }, []);


    const mapDashboardData = (response) => {
        const totalScheduled = response.data.totalSchedulingDay;
        const totalAttended = response.data.totalAttendScheduling;
        const totalForAnswering = response.data.totalSchedulinForAnswering;
        const totalCanceled = response.data.totalSchedulingCanceled;

        setNTotalAgendamentosPerc(Number(getPercentage(totalScheduled, 0, totalScheduled)));
        setNAtendidosPerc(Number(getPercentage(totalAttended, 0, totalScheduled)));
        setNPorAtenderPerc(Number(getPercentage(totalForAnswering, 0, totalScheduled)));
        setNCanceladosPerc(Number(getPercentage(totalCanceled, 0, totalScheduled)));

        setNTotalAgendamentos(totalScheduled);
        setNAtendidos(totalAttended);
        setNPorAtender(totalForAnswering);
        setNCancelados(totalCanceled);
    }
    

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    
    const handleClick = () => {
        setOpen(localStorage.getItem('showLoginSuccess') !== null);
    };

    setTimeout(()=> {
        localStorage.removeItem('showLoginSuccess');
        setOpen(false);
    }, 3000);
    
  return (
    <React.Fragment>

        { loading ? <Loading /> : 
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' , height: 240}}>
                    <Grid container spacing={2} columns={16}>
                        <Grid item xs={12}>
                            <img src={totalAgendamentos_img} alt="" />
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                            <ListItemText primary={nTotalAgendamentos} secondary={"Total"} />
                        </Grid>
                        <Grid item xs={12} mt={margin - 2}>
                            <h3>Agendamentos do dia</h3>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'} mt={margin-2}>
                            <h3>{nTotalAgendamentosPerc}{'%'}</h3>
                        </Grid>
                    </Grid>
                    <Progress value={Number(nTotalAgendamentosPerc)} color="info" />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                    <Grid container spacing={2} columns={16}>
                    <Grid item xs={12}>
                            <img src={totalAtendidos_img} alt="" />
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                            <ListItemText primary={nAtendidos} secondary={"Total"} />
                        </Grid>
                        <Grid item xs={12} mt={margin}>
                            <h3>Atendidos</h3>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'} mt={margin}>
                            <h3>{nAtendidosPerc} {'%'}</h3>
                        </Grid>
                    </Grid>
                    <Progress value={Number(nAtendidosPerc)} color="success" />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                    <Grid container spacing={2} columns={16}>
                        <Grid item xs={12}>
                            <img src={totalPorAtender_img} alt="" />
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                            <ListItemText primary={nPorAtender} secondary={"Total"} />
                        </Grid>
                        <Grid item xs={12} mt={margin}>
                            <h3>Por atender</h3>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'} mt={margin}>
                            <h3>{nPorAtenderPerc} {'%'}</h3>
                        </Grid>
                    </Grid>
                    <Progress value={Number(nPorAtenderPerc)} color="warning" />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 240 }}>
                    <Grid container spacing={2} columns={16}>
                        <Grid item xs={12}>
                            <img src={totalCancelados_img} alt="" />
                        </Grid>
                        <Grid item xs={4} textAlign={'right'}>
                            <ListItemText primary={nCancelados} secondary={"Total"} />
                        </Grid>
                        <Grid item xs={12} mt={margin}>
                            <h3>Cancelados</h3>
                        </Grid>
                        <Grid item xs={4} textAlign={'right'} mt={margin}>
                            <h3>{nCanceladosPerc} {'%'}</h3>
                        </Grid>
                    </Grid>
                    <Progress value={nCanceladosPerc} color="error" />
                </Paper>
            </Grid>
        </Grid>
        <Grid item xs={"auto"} md={4} marginTop={'40px'}>
            <img src={mapa} alt="mapa-guiné-bissau" className={styles.center_image} />
        </Grid>
        <Snackbar
            anchorOrigin={{
               vertical: "top",
               horizontal: "center"
            }}
            severity="success"
            variant="filled"
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
        >
            <Alert
                icon={false}
                sx={{ width: '100%', pr: 5, pl: 5 }}
            >
                Login efectuado com sucesso!
            </Alert>
        </Snackbar>
    </>
    }
    </React.Fragment>
  )
}
