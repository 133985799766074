import { CssBaseline, Grid, Typography } from "@mui/material";

export default function Copyright(props: any) {
    return (
        <Grid container spacing={2} columns={16}>
          <CssBaseline />
          <Grid item xs={10}>
          <Typography variant="body1" color="text.secondary" align="left" {...props}>
              {' © '} {new Date().getFullYear()} {' '}
                CGRGBA. Todos os Direitos Reservados.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              Powered by Nantoi Digital
            </Typography>
          </Grid>
        </Grid>
    );
  }