import { Container } from '@mui/material'
import React from 'react'

export default function CustomContainer({content}) {
  return (
    <React.Fragment>
        <Container sx={{ mb: 4, paddingLeft: 0, paddingRight: 0 }}>
            {content}
        </Container>
    </React.Fragment>
  )
}
