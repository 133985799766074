import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Alert, Toolbar, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { green } from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import labels from '../../../utils/Labels.json';
import default_news_image from '../../../assets/images/default_news_image.png';
import { dataURLtoFile } from '../../../utils/ImageUtils';

import {
  TextField,
  FormControl, 
  MenuItem, 
  InputLabel, 
  Select
} from '@mui/material';

import Popup from '../../../components/ui/Popup';

import { HttpResponseStatus } from '../../../models/common/HttpResponseStatus';
import Loading from '../../../components/ui/Loading';
import { useNavigate } from 'react-router';
import { PopupType } from '../../../utils/PopupType';
import PageHeaderSection from '../../../components/ui/PageHeaderSection';
import { extractErrorMessagesFromResponse } from '../../../utils/ResponseMessageUtils';

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );

const URL_NEWS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;

function AddNews() {
  const [title, setTitle] = useState('');
  const [category, setCategory]= useState('');
  const [description, setDescription]= useState('');
  const [image, setImage]= useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [invalidImageFormat, setIsInvalidImageFormat]=useState();
  const [invalidImageSize, setInvalidImageSize] = useState(false);
  const [done, setDone] = useState(false);
  const [cancelPopupOn, setCancelPopupOn] = useState(false);
  const [useDefaultImage, setUseDefaultImage] = useState(false);
  const [imageOption, setImageOption] = React.useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [newsCategoryList, setNewsCategoryList]= useState([]);


  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const createNews = async () => {
      try {

        setErrorMessage('');
        setIsError(false);
        setLoading(true);

        const formData = new FormData();
        formData.append('file', image);
        formData.append('title', title);
        formData.append('message', description);
        formData.append('categoryCode', category);

        const response = await axiosPrivate.post(URL_NEWS, formData);

        if (response.status !== HttpResponseStatus.OK) {
          return handleError(response);
        }

        setDone(true);
        setLoading(false);
        setImageOption(null);
        
      } catch (error) {
        setLoading(false);
        console.error("Error", error);
      }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createNews();
  };

  const handleTitle = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  }

  const handleCategory = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
  }

  const handleDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  }

  const URL_NEWS_CATEGORIES = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_NEWS_CATEGORIES}`;
  const MAX_IMAGE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB

  useEffect(() => {    
    const fetchNewsCategories = async () => {

      try {
        const response = await axiosPrivate.get(`${URL_NEWS_CATEGORIES}?v=${new Date().valueOf()}`);
        if (response.status !== HttpResponseStatus.OK) {
          return handleError(response);
        }

        setLoading(false);
        setNewsCategoryList(response.data.lovs);

      } catch (err) {
        setLoading(false);
          /*Expired token then force logout*/
        if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
          localStorage.clear();
          navigate('/login');
        }
        console.log("Error: ", err);
      }
    }

    fetchNewsCategories();
  },[]);

  const handleError = (response) => {
    setLoading(false);
    setIsError(true);
    if (!response || response.status === HttpResponseStatus.NOT_FOUND) {
      setErrorMessage(labels.common_generic_error);
      return;
    }

    const errors = extractErrorMessagesFromResponse(response);
    setErrorMessage(errors?.join('; '));

    return;
  }


  const handleImage = (e) => {
    const file = e.target.files[0];
    const validImageFormat = ['jpe', 'jpg', 'jpeg', 'png', 'svg', 'ico'];
    const fileExtention = file?.type.split('/')[1];

    if (file.size <= MAX_IMAGE_SIZE_LIMIT) {
      setInvalidImageSize(false);
      if (validImageFormat.includes(fileExtention)) {
        const image = URL.createObjectURL(file);
        setImagePreview(image);
        setImage(file);
        setIsInvalidImageFormat(false);
      } else {
        setIsInvalidImageFormat(true);
      }
    }
    else {
      setInvalidImageSize(true);
    } 
  }


  const enableButton = () => {
    return title?.trim() !== '' && category?.trim() !== '' && description?.trim() !== '' && image !== null;
  }

  const resetForm = () => {
    setTitle('');
    setCategory('');
    setDescription('');
    setImage(null);
    setImagePreview('');
    setDone(false);
    setCancelPopupOn(false);
  }


  const handleChange = (event) => {
    const imageOption = event.target.value;
    setImageOption(imageOption);
    setUseDefaultImage(imageOption === '1');

    if (imageOption === '0') {
      
      const ficheiro = dataURLtoFile(default_news_image, `image_${new Date().valueOf()}.png`);
     
      setImagePreview(default_news_image);
      setImage(ficheiro);
    }
    else {
      setImagePreview('');
      setImage(null);
    }
  }



  useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobileDevice = width <= 768;

			setIsMobile(isMobileDevice);
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);



  const addNewLine = (e) => {
    if (e.key === "Enter") {
      let newsText = description + '\n';
      setDescription(newsText);
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading && <Loading />}
      <Container maxWidth={'md'}>
        {
          isError &&
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                marginBottom: 10
              }}
            >
              <Alert variant="outlined" severity="error" sx={{marginTop: 10, width: 'auto'}}>
                {errorMessage}
              </Alert>
            </Grid>
        }
          {
            image &&
            <Popup 
              type={PopupType.SUCCESS}  
              content='Imagem foi adicionada com sucesso!'
              flag={image !== null}
              duration={3000}
            />
        }
        {
          <Popup 
              type={PopupType.ERROR}  
              content='Formato de imagem inválido'
              flag={invalidImageFormat}
              duration={3000}
            />
        }
         {
          <Popup 
            type={PopupType.SUCCESS}  
            content='O tamanho máximo de imagem permitido é de 5MB'
            flag={invalidImageSize}
            duration={3000}
          />
        }
      </Container>
      <Container component="main" sx={{ mb: 4 }}>
        <PageHeaderSection
          title='Publicar notícia'
          description='Publicação de notíca a ser apresentada no portal consular.'
        />
        <Paper elevation={4} component={Container}>
          {done ? (
            <React.Fragment>
              <Typography variant="subtitle1" textAlign={'center'} sx={{pt: 5, pb: 5}}>
                A notícia foi criada com sucesso!
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Toolbar
                sx={{
                  pl: { sm: 0 },
                  pr: { xs: 1, sm: 1 },
                  paddingLeft: isMobile ? 0 : ''
                }}
              >
                <Typography variant="h4" fontWeight={600}>Dados da publicação</Typography>
              </Toolbar>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} marginTop={2}>
                  <TextField
                    required
                    label="Título"
                    fullWidth
                    autoComplete="title"
                    variant="standard"
                    value={title}
                    onChange={handleTitle}
                    inputProps={{maxLength: 250}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} marginTop={2}>
                  <FormControl variant="standard" fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="category-label">Categoria</InputLabel>
                    <Select
                      required
                      labelId="category-label"
                      label="Categoria"
                      value={category}
                      onChange={handleCategory}
                    >
                      {newsCategoryList?.map(news => (
                        <MenuItem value={news.code} key={news.code}>{news.description}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} marginTop={5}>
                  <TextField
                    required
                    label="Descrição"
                    multiline
                    rows={10}
                    variant="outlined"
                    value={description}
                    onChange={handleDescription}
                    onKeyDown={addNewLine}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} paddingBottom={5}>
                  <FormControl sx={{marginBottom: '20px'}}>
                    <RadioGroup
                      aria-labelledby="radio-image"
                      name="radio-image"
                      value={imageOption}
                      onChange={handleChange}
                      required
                    >
                      <FormControlLabel value="0" control={<Radio />} label="Imagem padrão" />
                      <FormControlLabel value="1" control={<Radio />} label="Escolher imagem personalizada" />
                    </RadioGroup>
                  </FormControl>
                  <div>
                    {
                      useDefaultImage &&
                      <Button
                        variant="outlined"
                        component="label"
                        sx={{ height: 40, textTransform: 'none' , fontSize: '1.8rem'}}
                        startIcon={<CloudUploadIcon />}
                      >
                        Carregar imagem
                        <input
                          accept=".png, .jpg, .jpeg"
                          type="file"
                          hidden
                          onChange={handleImage}
                        />
                      </Button>
                    }
                  </div>
                </Grid>
                <Container sx={{minHeight: '200px', marginBottom: '50px'}}>
                  <Typography variant="h5" gutterBottom>
                    Previsualização da imagem:
                  </Typography>
                  <Paper elevation={5}>
                    <img src={imagePreview} style={{width: '100%', display: 'block', overflow: 'none'}}/>
                  </Paper>
                </Container>
              </Grid>
          </React.Fragment>
        )}
        </Paper>

      { !done && 

        <Box
          component="span"
          marginTop={5}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          paddingLeft={0}
        >
          <Button 
            variant="contained"
            type='submit' 
            sx={{ height: 40, ml: 5, textTransform: 'none' , fontSize: '18px'}}
            size='medium'
            onClick={handleSubmit}
            disabled={!enableButton() || loading}
            endIcon={<ArrowForwardIcon />}
            >
            Criar notícia
          </Button>
        </Box>
      }

    { done &&
      <Box
        component="span"
        marginTop={5}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        paddingLeft={0}
      >
        <Button 
          variant="contained"
          type='submit' 
          sx={{ height: 40, ml: 5, textTransform: 'none' , fontSize: '18px'}}
          size='medium'
          onClick={resetForm}
          startIcon={<AddIcon />}
          >
          Criar outra notícia
        </Button>
      </Box>
      }
    </Container>
  </ThemeProvider>
  );
}

export default AddNews