import React, {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {Button, Tooltip} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel} from '@mui/material';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Container, Grid, Toolbar , TextField} from '@mui/material';
import DataInfo from '../../components/ui/DataInfo';
import {getFormattedDateAndHours, getFormattedFullDate} from '../../utils/DateUtils';
import PageHeaderSection from '../../components/ui/PageHeaderSection';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/pt';
import Divider from '@mui/material/Divider';
import { SchedulingStatus } from '../../utils/SchedulingStatus';

import { SearchFilterType } from '../../utils/SearchFilterType';
import { useNavigate } from 'react-router';
import Popup from '../../components/ui/Popup';
import { PopupType } from '../../utils/PopupType';

import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Done } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import CustomContainer from '../../components/ui/CustomContainer';

const URL_SCHEDULINGS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_SCHEDULINGS}`;

let theme = createTheme(
  {
    palette: {
      primary: {
        main: '#506E58',
      },
      secondary: {
        main: green[500],
      },
    },
  }
);

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [endSchedulingPopupOn, setEndSchedulingPopupOn] = useState(false);
  const [startSchedulingPopupOn, setStartSchedulingPopupOn] = useState(false);
  const [cancelSchedulingPopupOn, setCancelSchedulingPopupOn] = useState(false);

  const [selectedCitizenName, setSelectedCitizenName] = useState('');
  const [selectedSchedulingId, setSelectedSchedulingId] = useState('');
  const [disableCancel, setDisableCancel] = useState(true);

  const reasonRef = useRef();

  const axiosPrivate = useAxiosPrivate();
  
    
  const handleCloseStartScheduling = () => {
    setStartSchedulingPopupOn((prev) => !prev);
  }

  const handleStartScheduling = () => {
    setStartSchedulingPopupOn((prev) => !prev);
  }


  const updateStatusToAnswered = () => {
    handleEndScheduling();
    updateSchedulingStatus(SchedulingStatus.ANSWERED);
  }

  const updateStatusToInProgress = () => {
    handleStartScheduling();
    updateSchedulingStatus(SchedulingStatus.IN_PROGRESS);
  }

  const updateStatusToInCancel = () => {
    handleCancelScheduling();
    updateSchedulingStatus(SchedulingStatus.CANCELED);
  }

  const handleReason = () => {
    setDisableCancel(reasonRef?.current?.value?.trim() === '');
  }


  const handleEndScheduling = () => {
    setEndSchedulingPopupOn((prev) => !prev);
  }

  const handleCancelScheduling = () => {
    setCancelSchedulingPopupOn((prev)=> !prev);
  }

  const updatePopupCitizenNameAndSchedulingId= (name, schedulingId) => {
    setSelectedCitizenName(name);
    setSelectedSchedulingId(schedulingId);
  }


  const updateSchedulingStatus = async (status) => {
    try {
      setLoading(true);
      const response = await axiosPrivate.put(`${URL_SCHEDULINGS}/${selectedSchedulingId}/status`, {schedulingStatusCode: `${status}`, reason: `${reasonRef?.current?.value}` });
      
      if (response.status !== HttpResponseStatus.OK) {
        setSuccess(false);
        return;
      }
      row.status.code=response?.data?.scheduling?.status?.code;
      row.status.description=response?.data?.scheduling?.status?.description;
      row.updateDate=response?.data?.scheduling?.updateDate;
      row.attendDate=response.data.scheduling.attendDate;

      setSuccess(true);
      setLoading(false);

    } catch (err) {
      setLoading(false);
      setSuccess(false);

      /*Expired token then force logout*/
      if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
        localStorage.clear();
        navigate('/login');
        return;
      }
      console.log("Error: ", err);
    }
  }

  

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Typography variant="h6" gutterBottom>
            {row.citizenFullName}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {row.service?.category?.description}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {row.service?.name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {row.chosenHour}
          </Typography>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {setOpen(!open); updatePopupCitizenNameAndSchedulingId(row.citizenFullName, row.id);}}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} paddingBottom={2}>
              <Grid item xs={4} minWidth={'md'}>
                <DataInfo label="Efetuado em:" content={getFormattedDateAndHours(row.creationDate)} />
                <DataInfo label="Última atualização:" content={getFormattedDateAndHours(row.updateDate)} />
              </Grid>
              <Grid item xs={4}>
                <DataInfo label="Data agendamento:" content={getFormattedFullDate(row.date)} />
                <DataInfo label="Data e hora atendimento:" content={getFormattedDateAndHours(row.attendDate)} />
              </Grid>
              <Grid item xs={4}>
                <DataInfo label="Email:" content={row?.citizen?.email} />
                <DataInfo label="Estado:" content={row?.status?.description} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
          <Box
            component="span"
            marginTop={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            paddingLeft={0}
            sx={{mb: 10}}
          >
            { 
            (row?.status?.code === SchedulingStatus.FOR_ANSWERING ||
              row?.status?.code === SchedulingStatus.IN_PROGRESS) &&
              <Button
                variant="outlined"
                color="error"
                sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
                onClick={handleCancelScheduling}
                startIcon={<ClearIcon />}
              >
                Cancelar
              </Button>
            }
            
            {row?.status?.code === SchedulingStatus.FOR_ANSWERING && row.editable &&
              <Button 
                variant="outlined"
                type='submit' 
                sx={{height: 40, ml: 5, textTransform: 'none' , fontSize: '18px'}}
                size='medium'
                disabled={loading}
                onClick={handleStartScheduling}
                endIcon={<ArrowForwardIcon />}
              >
                Atender
              </Button>
            }
            
            { row?.status?.code === SchedulingStatus.IN_PROGRESS && row.editable &&
              <Button 
                variant="contained"
                color='primary'
                type='submit' 
                sx={{height: 40, ml: 5, textTransform: 'none' , fontSize: '18px'}}
                size='medium'
                disabled={loading}
                onClick={handleEndScheduling}
                endIcon={<Done />}
                >
                  Terminar
              </Button>

            }
            
          </Box>

          </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
              fullScreen={fullScreen}
              open={cancelSchedulingPopupOn}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title" sx={{pt: 3}}>
                {`Deseja cancelar o agendamento do(a) Sr.(a) ${selectedCitizenName}?`}
              </DialogTitle>
              <DialogContent>
                <TextField
                  inputRef={reasonRef}
                  required
                  autoFocus
                  fullWidth
                  margin="dense"
                  id="reason"
                  label="Motivo do cancelamento"
                  type="reason"
                  variant="standard"
                  onChange={handleReason}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleCancelScheduling}
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Sair
                </Button>
                <Button onClick={updateStatusToInCancel} autoFocus
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                  disabled={disableCancel}
                >
                  Sim, cancelar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullScreen={fullScreen}
              open={startSchedulingPopupOn}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title" sx={{pt: 3}}>
                {`Deseja iniciar o atendimento do(a) Sr.(a) ${selectedCitizenName}?`}
              </DialogTitle>
              <DialogActions>
                <Button autoFocus onClick={handleCloseStartScheduling}
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Sair
                </Button>
                <Button onClick={updateStatusToInProgress} autoFocus
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Sim, iniciar
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullScreen={fullScreen}
              open={endSchedulingPopupOn}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Concluir o agendamento"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Deseja terminar este atendimento? 
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleEndScheduling}
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Não
                </Button>
                <Button onClick={updateStatusToAnswered} autoFocus
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Sim, terminar
                </Button>
              </DialogActions>
            </Dialog>

            {
              <Popup 
                type={PopupType.SUCCESS}  
                content='O estado do agendamento foi atualizado com sucesso!'
                flag={success}
                duration={3000}
              />
            }
    </React.Fragment>
  );
}

export default function ListSchedulings() {
  const [filterOn, setFilterOn] = React.useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState('');

  const [schedulingList, setSchedulingList] = useState([]);
  const [loading, setLoading] = useState();
  const [dateChecked, setDateChecked] = useState(false);
  const[statusChecked, setStatusChecked] = useState(false);
  const [filters, setFilters]= React.useState([]);
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  
  const today = getFormattedFullDate(new Date(),'YYYY-MM-DD', false);

  const statusList = [
                      {id: 'ALL', description: 'Todos'},
                      {id: 'FOR_ANSWERING', description: 'Por atenter'},
                      {id: 'IN_PROGRESS', description: 'Em curso'},
                      {id: 'ANSWERED', description: 'Atendido'},
                      {id: 'CANCELED', description: 'Cancelado'}
                    ]

  const fetchSchedulings = async (url) => {

    try {
      setLoading(true);
      const mark = new Date().valueOf();
      url.includes('?') ? url += '&v=' + mark : url += '?v=' + mark;
      const response = await axiosPrivate.get(url);

      if (response.status !== HttpResponseStatus.OK) {
        return;
      }

      setSchedulingList(response.data.content);
      setLoading(false);

    } catch (err) {
      setLoading(false);

      /*Expired token then force logout*/
      if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
        localStorage.clear();
        navigate('/login');
      }
    }
  }

  useEffect(() => {
    handleSearch();
  },[]);


  const getFormattedURL= () => {
    let url = `${URL_SCHEDULINGS}?pageSize=9999&`;
    
    if (filters?.includes(SearchFilterType.DATE) && filters?.includes(SearchFilterType.STATUS)) {
      url = `${url}beginSchedulingDate=${selectedDate}&schedulingStatus=${selectedStatus}`;
    }
    else {
      if (filters?.includes(SearchFilterType.DATE)) {
        url = `${url}beginSchedulingDate=${selectedDate}`;
      }
      else if (filters?.includes(SearchFilterType.STATUS)) {
        url = `${url}schedulingStatus=${selectedStatus}`;
      }
      else {
        // SearchFilterType.DEFAULT
        url = `${url}beginSchedulingDate=${today}&schedulingStatus=FOR_ANSWERING`;
      }

    }
    return url;
  }



  const handleEndSchedulingDate = (date) => {
    const selectedDate = date?.format('YYYY-MM-DD');
    setSelectedDate(selectedDate);
  }


  const handleSchedulingStatus = (e) => {
    setSelectedStatus(e.target.value);
   }


   const handleDateFilter=(e) => {
    setDateChecked(e.target.checked);
    e.target.checked ? addFilter(SearchFilterType.DATE) : removeFilter(SearchFilterType.DATE);
   }

   const handleStatusFilter = (e) => {
    setStatusChecked(e.target.checked);
    e.target.checked ? addFilter(SearchFilterType.STATUS) : removeFilter(SearchFilterType.STATUS);
   }

   const addFilter = (filter) => {
    filters.push(filter);
   }


   const removeFilter = (filter) => {
    setFilters(filters.filter((f) => f !== filter))
   }

   function disableWeekends(date) {
    return date.$W === 0 || date.$W === 6;
  }


  const handleSearch = (e) => {
    e?.preventDefault();
    let url = getFormattedURL();
    fetchSchedulings(url);
  }


  const refreshData = () => {
    handleSearch();
  }

  
  return (
    <ThemeProvider theme={theme}>
      <CustomContainer
        content={
          <>
      <PageHeaderSection 
        title='Consultar agendamentos'
        description='Lista de agendamentos efetuados para os atos consulares.'
      />
      <Paper  elevation={2} component={Container}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%', mt: 5, mb:5 }}
            variant="h4"
            component="div"
            fontWeight={'600'}
          >
            Agendamentos
          </Typography>
          <Tooltip title="Atualizar" sx={{mr: 5}}>
            <IconButton
                onClick={refreshData}
              >
                <CachedIcon />
            </IconButton>
            </Tooltip>
        {
          <Button
            variant="text"
            sx={{color: '#000'}}
            onClick={() => setFilterOn(!filterOn)}
          >
            <Typography variant="subtitle1" gutterBottom sx={{ fontSize: 5, textTransform: 'none'}}>Filtros</Typography>
            {filterOn ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        }
        </Toolbar>
        {
        <Collapse in={filterOn} timeout="auto" unmountOnExit sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, marginBottom: 5, marginTop: 5}}>
          <Box sx={{ margin: 1 }}>
          <Typography variant="h6">Filtros selecionados ({filters.length})</Typography>
            <Paper sx={{border: 'none'}} elevation={0}>
              <Box sx={{ margin: 1, pb: 5 }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={dateChecked} onChange={handleDateFilter} />} label="Data do agendamento" />
                  <FormControlLabel control={<Checkbox checked={statusChecked} onChange={handleStatusFilter} />} label="Estado do agendamento" />
                </FormGroup>
              </Box>
            
              
            <Grid container spacing={3} sx={{mb: 5}}>
              { dateChecked &&
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <DatePicker
                      onChange={handleEndSchedulingDate}
                      label={'Data do agendamento'}
                      shouldDisableDate={disableWeekends}
                      slotProps={{ textField: { fullWidth: true, variant:'standard', required: true } }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              }
              {
                statusChecked &&
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormControl variant="standard" fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="intervalType-label">Estado do agendamento</InputLabel>
                    <Select
                      required
                      labelId="intervalType-label"
                      id="intervalType"
                      label="Estado do agendamento"
                      onChange={handleSchedulingStatus}
                      value={selectedStatus}
                    >
                      { statusList.map((status, key) => (
                          <MenuItem value={status.id} key={key}>{status.description}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            }
          </Grid>
          <Grid>
            <Box
              component="span"
              marginTop={2}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              paddingLeft={0}
              >
                <Button 
                  variant="contained"
                  type='submit' 
                  sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
                  disabled={loading}
                  onClick={handleSearch}
                  startIcon={<SearchIcon sx={{transform: "rotate(90deg)"}} />}
                  >
                  Pesquisar
                </Button>
              </Box>
          </Grid>
        </Paper>
      </Box>
    </Collapse>
    }
    <Divider sx={{ mb:5}} />

  { (!schedulingList || schedulingList?.length < 1) && !loading ?
      <>
      <Typography 
        sx={{ flex: '1 1 100%', paddingBottom: 5, marginLeft: 2, pb: 10 }}
        variant="h4"
        component="div" gutterBottom marginBottom={5}>
          Sem resultados para apresentar
      </Typography>
      
      </> :
      <>

    { loading ? 
        <Typography variant="h6" gutterBottom paddingBottom={5} paddingTop={5} sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pt: 5 }}>
          Por favor aguarde...
        </Typography> :
        <TableContainer sx={{marginTop: 6}}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" gutterBottom sx={{fontWeight: '600'}}>Nome</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" gutterBottom sx={{fontWeight: '600'}}>Categoria</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" gutterBottom sx={{fontWeight: '600'}}>Serviço</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5" gutterBottom sx={{fontWeight: '600'}}>Hora</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody> 
                {schedulingList?.map(row => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        }
        </>
        }
      </Paper>
      
      </>}
      />
    </ThemeProvider>
  );
}
