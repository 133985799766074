const valid_domains = process.env.REACT_APP_VALID_DOMAIN;
const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 30;

export const isValidPassword = (password) => {
    /*
        ^                         Start anchor
        (?=.*[A-Z].*[A-Z])        Ensure string has one uppercase letter.
        (?=.*[!@#$&*])            Ensure string has one special case letter.
        (?=.*[0-9].*[0-9])        Ensure string has one digit.
        (?=.*[a-z].*[a-z].*[a-z]) Ensure string has one lowercase letter.
        .{8}                      Ensure string is of length 8.
        $                         End anchor.
        */
        /*const regex = `^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$`;
        return password && password.match(regex);
        */
       return password 
                && password?.trim().length >= MIN_PASSWORD_LENGTH 
                && password?.trim().length <= MAX_PASSWORD_LENGTH;
}


export const isValidEmail = (email) => {
    const validDomains = valid_domains.split(',');
    const startIndex = email?.indexOf('@');
    const inputDomain = email?.substring(startIndex + 1, email?.length);
    return email && email.length >= 6 && validDomains.includes(inputDomain);
}

export const isValidFormData=(email, password)=>{
    return isValidEmail(email) && isValidPassword(password);
}


