import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Header from './Header';
import Drawer from '@mui/material/Drawer';

import AppBar from '@mui/material/AppBar';
import {Collapse, Grid} from '@mui/material';


import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PasswordIcon from '@mui/icons-material/Password';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';



import SignUp from '../users/SignUp';
import ChangeInitialPassword from '../users/ChangeInitialPassword';
import AddTimeSlots from '../scheduling/AddTimeSlots';
import ListTimeSlots from '../scheduling/ListTimeSlots';
import ListSchedulings from '../scheduling/ListSchedulings';
import AddNews from '../portal/news/AddNews';
import ListNews from '../portal/news/ListNews';
import HomePage from './HomePage';
import PageNotFound from './PageNotFound';
import { useState} from 'react';



import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ReorderIcon from '@mui/icons-material/Reorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import LanguageIcon from '@mui/icons-material/Language';

import { PageCode } from '../../utils/PageCode';

import logo from './../../assets/images/logo-portal-consular.jpg';
import ListUsers from '../users/ListUsers';
import Settings from '../users/AccountDetails';
import ChangePassword from '../users/ChangePassword';
import useAuth from '../../hooks/useAuth';

function Copyright() {
  return (

    <Grid container textAlign={'center'} spacing={{xs: 2, md:3}} columns={{xs:4, sm:8, md:12}}>
      <Grid item xs={2} sm={4} md={4}>
      <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
        CGRGBA
        {' '}
      {new Date().getFullYear()}.
    </Typography>
      </Grid>
      <Grid item xs={2} sm={4} md={4}>
      <Typography variant="body2" color="text.secondary" align="right" justifyContent={'flex-end'}>
      {'Powered by '}
      <Link to={'https://www.nantoidigital.com'} target='_blank' style={{textDecoration: 'none'}} color="inherit">
        Nantoi Digital
      </Link>{' '}
    </Typography>
        
      </Grid>

    </Grid>
    
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h4: {
      fontWeight: 500,
      fontSize: 28,
      letterSpacing: 0.5,
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 16,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 330;

export default function Dashboard() {
  const { auth } = useAuth();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (code) => {
    toggleDrawer();
    switch(code) {
      case 0:
        setInnerMenuAgendamentoOn(!innerMenuAgendamentoOn);
        break;
      case 1:
        setInnerMenuUtilizadorOn(!innerMenuUtilizadorOn);
        break;
      case 2:
        setInnerMenuPortalOn(!innerMenuPortalOn);
        break;
      case 3:
      default:
        setInnerMenuContaOn(!innerMenuContaOn);
        break;
    }
    setOpen(true);
  }

  const [activeMenu, setActiveMenu] = useState(PageCode.HOME_PAGE);
 
  const [open, setOpen] = useState(true);

  const [innerMenuAgendamentoOn, setInnerMenuAgendamentoOn] = useState(false);
  const [innerMenuUtilizadorOn, setInnerMenuUtilizadorOn] = useState(false);
  const [innerMenuPortalOn, setInnerMenuPortalOn] = useState(false);
  const [innerMenuContaOn, setInnerMenuContaOn] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);

    if (open) {
      setInnerMenuAgendamentoOn(false);
      setInnerMenuUtilizadorOn(false);
      setInnerMenuPortalOn(false); 
      setInnerMenuContaOn(false);
    }
  };


  const item = {
    py: '2px',
    px: 3,
    color: '#000',
    '&:hover, &:focus': {
      bgcolor: '#FFF',
    },
  };
  
  const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3
  };



  const changeContent = (pageCode) => {
    setActiveMenu(pageCode);
    setMobileOpen(false);
  }


  const hasPermisson = (AllowedRoles) => {
    const roles = auth?.roles?.filter(role => AllowedRoles.includes(role));
    return roles.length > 0;
  }

  const goToHomePage = (e) => {
    e.preventDefault();
    changeContent(PageCode.HOME_PAGE);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          
    {isSmUp ? null : (
    <Drawer 
      variant="temporary" 
      PaperProps={{ style: { width: drawerWidth } }}
      open={mobileOpen}
      onClose={handleDrawerToggle}
    >
              <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#000'}}>
                  <Grid container justifyContent="center">
                    <img href="/" onClick={goToHomePage} src={logo} alt='logo' width={200}/>
                  </Grid>
                </ListItem>
                <ListItem sx={{ ...item, ...itemCategory, pl: 2 }}>
                  <ListItemIcon>
                    <HomeIcon sx={{pl: -5}}/>
                  </ListItemIcon>
                  <ListItemText>Menu</ListItemText>
                </ListItem>
                <Box key={1} sx={{ bgcolor: '#FFF' }}>
                  {hasPermisson(['ADM','GES', 'COL', 'FUN']) && 
                  <>
                  {
                  hasPermisson(['ADM','GES', 'COL', 'FUN']) && 
                  <>
                    <ListItemButton selected={activeMenu===0} sx={{paddingBottom: '20px'}} onClick={()=> handleClick(0)}>
                      <ListItemIcon>
                        <CalendarMonthIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Gestão de agendamentos' />
                      {innerMenuAgendamentoOn ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </>
                  }  
                  <Collapse in={innerMenuAgendamentoOn} timeout="auto" unmountOnExit>
                  {
                    hasPermisson(['ADM', 'GES', 'COL', 'FUN']) && 
                    <>
                      <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_SCHEDULINGS)}>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <ReorderIcon />
                          </ListItemIcon>
                          <ListItemText primary='Consultar agendamentos' />
                        </ListItemButton>
                      </ListItem>
                    </>
                    }
                    {
                      hasPermisson(['ADM', 'GES', 'FUN']) && 
                      <>
                        <ListItem disablePadding onClick={()=> changeContent(PageCode.ADD_TIME_SLOTS)}>
                          <ListItemButton sx={{ pl: 4 }} >
                            <ListItemIcon>
                              <AddCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary='Criar faixas horárias' />
                          </ListItemButton>
                        </ListItem>
                      </>
                    }
                    {
                      hasPermisson(['ADM', 'GES']) && 
                      <>
                        <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_TIME_SLOTS)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <ReorderIcon />
                            </ListItemIcon>
                            <ListItemText primary='Consultar faixas horárias'  />
                          </ListItemButton>
                        </ListItem>
                      </>
                    }
                  </Collapse>
                  {
                    hasPermisson(['ADM','GES']) && 
                  <>
                    <ListItemButton sx={{paddingBottom: '20px'}} onClick={()=> handleClick(1)}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary='Gestão de utilizadores' />
                      {innerMenuUtilizadorOn ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </>
                  }   
                  <Collapse in={innerMenuUtilizadorOn} timeout="auto" unmountOnExit>
                  {hasPermisson(['ADM']) && 
                  <>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.SIGN_UP)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary='Criar utilizador' />
                      </ListItemButton>
                    </ListItem>
                    </>}
                    {
                    hasPermisson(['ADM','GES']) && 
                    <>
                      <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_USERS)}>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <ReorderIcon />
                          </ListItemIcon>
                          <ListItemText primary='Consultar utilizadores' />
                        </ListItemButton>
                      </ListItem>
                    </>
                  }
                  </Collapse>
                  {
                    hasPermisson(['ADM','GES']) && 
                    <>
                      <ListItemButton sx={{paddingBottom: '20px'}} onClick={()=> handleClick(2)}>
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary='Gestão do portal' />
                        {innerMenuPortalOn ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </>
                  }
                  {
                    hasPermisson(['ADM','GES']) && 
                    <>   
                      <Collapse in={innerMenuPortalOn} timeout="auto" unmountOnExit> 
                        <ListItem  disablePadding onClick={()=> changeContent(PageCode.ADD_NEWS)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AddCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary='Publicar notícia' />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_NEWS)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <ReorderIcon />
                            </ListItemIcon>
                            <ListItemText primary='Consultar notícias' />
                          </ListItemButton>
                        </ListItem>
                      </Collapse>
                    </>
                  }

                  <Divider sx={{ mt: 10 }} />
                  </> 
                  }
                
                  <ListItemButton sx={{paddingBottom: '20px',}} onClick={()=> handleClick(4)}>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary='Definições' />
                    {innerMenuContaOn ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>   
                  <Collapse in={innerMenuContaOn} timeout="auto" unmountOnExit>
                    <ListItem  disablePadding onClick={()=> changeContent(PageCode.ACCOUNT_DETAILS)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary='Detalhes da conta' />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.CHANGE_PASSWORD)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ReorderIcon />
                        </ListItemIcon>
                        <ListItemText primary='Alterar palavra-passe' />
                      </ListItemButton>
                    </ListItem>
                  </Collapse>
                </Box>
              </List>
            </Drawer>
          )}


          <Drawer 
            variant="permanent" 
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
          >
            <List disablePadding>
              <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#000', textAlign: 'center' }}>
                <Grid container justifyContent="center">
                  <img onClick={goToHomePage} src={logo} alt='logo' width={200}/>
                </Grid>
              </ListItem>
              <ListItem sx={{ ...item, ...itemCategory, pl: 2 }}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText>Menu</ListItemText>
              </ListItem>
                <Box key={1} sx={{ bgcolor: '#FFF' }}>

                  {
                    hasPermisson(['ADM','GES', 'COL', 'FUN']) && 
                    <>
                      <ListItemButton sx={{paddingBottom: '20px'}} onClick={()=> handleClick(0)}>
                        <ListItemIcon>
                          <CalendarMonthIcon/>
                        </ListItemIcon>
                        <ListItemText primary='Gestão de agendamentos' />
                        {innerMenuAgendamentoOn ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton> 
                    
                  <Collapse in={innerMenuAgendamentoOn} timeout="auto" unmountOnExit>
                  {
                    hasPermisson(['ADM','GES', 'COL','FUN']) && 
                  <>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_SCHEDULINGS)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ReorderIcon />
                        </ListItemIcon>
                        <ListItemText primary='Consultar agendamentos' />
                      </ListItemButton>
                    </ListItem>
                  </>
                  }
                  {
                  hasPermisson(['ADM','GES', 'FUN']) && 
                  <>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.ADD_TIME_SLOTS)}>
                      <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon>
                          <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary='Criar faixas horárias' />
                      </ListItemButton>
                    </ListItem>
                  </>
                  }
                  {
                  hasPermisson(['ADM','GES']) && 
                  <>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_TIME_SLOTS)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ReorderIcon />
                        </ListItemIcon>
                        <ListItemText primary='Consultar faixas horárias'  />
                      </ListItemButton>
                    </ListItem>
                  </>
                  }
                  </Collapse>
                  </>
                  }
                  {
                  hasPermisson(['ADM','GES']) && 
                  <>
                  <ListItemButton sx={{paddingBottom: '20px'}} onClick={()=> handleClick(1)}>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary='Gestão de utilizadores' />
                    {innerMenuUtilizadorOn ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>   
                  <Collapse in={innerMenuUtilizadorOn} timeout="auto" unmountOnExit>
                  {
                    hasPermisson(['ADM']) && 
                    <>
                      <ListItem disablePadding onClick={()=> changeContent(PageCode.SIGN_UP)}>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <AddCircleOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary='Criar utilizador' />
                        </ListItemButton>
                      </ListItem>
                    </> 
                  }
                  {
                    hasPermisson(['ADM','GES']) && 
                    <>
                      <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_USERS)}>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <ReorderIcon />
                          </ListItemIcon>
                          <ListItemText primary='Consultar utilizadores' />
                        </ListItemButton>
                      </ListItem>
                    </>
                  }
                  </Collapse>
                  </>
                  }
                  {
                  hasPermisson(['ADM','GES']) && 
                  <>
                  <ListItemButton sx={{paddingBottom: '20px'}} onClick={()=> handleClick(2)}>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary='Gestão do portal' />
                    {innerMenuPortalOn ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>   
                  <Collapse in={innerMenuPortalOn} timeout="auto" unmountOnExit>
                    {
                      hasPermisson(['ADM','GES']) && 
                      <>
                        <ListItem  disablePadding onClick={()=> changeContent(PageCode.ADD_NEWS)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AddCircleOutlineIcon />
                            </ListItemIcon>
                            <ListItemText primary='Publicar notícia' />
                          </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={()=> changeContent(PageCode.LIST_NEWS)}>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <ReorderIcon />
                            </ListItemIcon>
                            <ListItemText primary='Consultar notícias' />
                          </ListItemButton>
                        </ListItem>
                      </>
                    }
                  </Collapse>
                <Divider sx={{ mt: 10 }} />
                </>
                }

                <ListItemButton sx={{paddingBottom: '20px',}} onClick={()=> handleClick(4)}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Definições de conta' />
                    {innerMenuContaOn ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>   
                  <Collapse in={innerMenuContaOn} timeout="auto" unmountOnExit>
                    <ListItem  disablePadding onClick={()=> changeContent(PageCode.ACCOUNT_DETAILS)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary='Detalhes da conta' />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={()=> changeContent(PageCode.CHANGE_PASSWORD)}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <PasswordIcon />
                        </ListItemIcon>
                        <ListItemText primary='Alterar palavra-passe' />
                      </ListItemButton>
                    </ListItem>
                  </Collapse>
              </Box>
            </List>
          </Drawer>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#F3F3F3', bgcolor: '#F3F3F3'}}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#F3F3F3' }}>
          <Box sx={{ maxWidth: 1200, margin: 'auto', overflow: 'hidden', backgroundColor: '#F3F3F3' }}>
            <AppBar
              position="static"
              color="transparent"
              elevation={0}
              sx={{ borderBottom: 'none'}}
            >

              { activeMenu === PageCode.SIGN_UP && <SignUp /> }
              { activeMenu === PageCode.CHANGE_INITIAL_PASSWORD && <ChangeInitialPassword /> }
              { activeMenu === PageCode.ADD_TIME_SLOTS && <AddTimeSlots /> }
              { activeMenu === PageCode.LIST_TIME_SLOTS && <ListTimeSlots /> }
              { activeMenu === PageCode.LIST_SCHEDULINGS && <ListSchedulings /> }
              { activeMenu === PageCode.ADD_NEWS && <AddNews /> }
              { activeMenu === PageCode.LIST_NEWS && <ListNews /> }
              { activeMenu === PageCode.HOME_PAGE && <HomePage /> }
              { activeMenu === PageCode.LIST_USERS && <ListUsers /> }
              { activeMenu === PageCode.ACCOUNT_DETAILS && <Settings /> }
              { activeMenu === PageCode.CHANGE_PASSWORD && <ChangePassword /> }
              { activeMenu === PageCode.PAGE_NOT_FOUND && <PageNotFound /> }
            </AppBar>

            </Box>
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}