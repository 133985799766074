import { Typography } from "@mui/material";

export default function DataInfo({label, content}) {
  return (
    <Typography variant="body1" gutterBottom sx={{ mt: 2, color: '#908E9B' }} >
        {label} <br/>
        <span style={{color: '#565360'}}>
        {content}
        </span>
    </Typography>
  )
}
