import {useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import Loading from '../components/ui/Loading';
import { Box, Container, CssBaseline, Grid } from '@mui/material';

export default function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {}
            finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.refreshToken ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, []);

    return (
        <>
            
            {
            isLoading ? 
            <>
                <Container fixed>
                    <CssBaseline />
                    <Box
                        sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                        <Grid container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: '100vh' }}
                        >
                            <Grid marginTop={0}>
                                <Loading />
                            </Grid> 
                        </Grid>
                    </Box>
                </Container>
            </> 
            : <Outlet />
            }
        </>
    )
}
