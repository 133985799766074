import {checkboxClasses, Box, Button, Checkbox, CssBaseline, FormControl, Container, Grid, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Typography, createTheme , ThemeProvider, Input, FormHelperText} from '@mui/material';
import { useEffect, useState } from 'react';
import { PopupType } from '../../utils/PopupType';
import 'dayjs/locale/pt';
import { AddUserParams } from '../../models/users/AddUserParams';
import Loading from '../../components/ui/Loading';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import labels from '../../utils/Labels.json';
import PageHeaderSection from '../../components/ui/PageHeaderSection';
import Popup from '../../components/ui/Popup';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { green } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SignUp() {
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [invalidNumberFormat, setInvalidNumberFormat] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const telephoneRegex = /^(\+351|\+351 |00351|00351 )?[9][0-9]{8}$/;
 
  const URL_ROLES = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_ROLES}`;
  const URL_USERS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_USERS}`;


  useEffect(() => {
    const fetchRoles = async () => {
    try {
      const response = await axiosPrivate.get(URL_ROLES);
      
      if (response.status !== HttpResponseStatus.OK) {
        handleError(response);
      }

      setRoleList(response.data.lovs);
    } catch (err) {
      handleError(err?.response);
    }
  }

  fetchRoles();
},[roles])


  const clearForm = () => {
    setFullName('');
    setMobileNumber('');
    setEmail('');
    setRoles([]);
  }


  const addUser = async (data) => { 
    setLoading(true);
    try {
      const response = await axiosPrivate.post(URL_USERS, JSON.stringify(data));
      
      if (response.status !== HttpResponseStatus.OK) {
        handleError(response);
      }
  
      setLoading(false);
      setIsSuccess(true);
      setIsError(false);
      clearForm();
    }
    catch (error) {
      setLoading(false);
      setIsSuccess(false);
      setIsError(true);
      handleError(error.response);
    }
    
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const userParams = new AddUserParams(fullName, mobileNumber, email, selectedRoles);
    addUser(userParams);
  };



  const handleError = (response) => {
    const error = response?.status;
    setIsError(true);

    if (!response || error === HttpResponseStatus.NOT_FOUND) {
      setErrorMessage(labels.common_generic_error);
      return;
    } 

    const errors = extractErrorMessagesFromResponse(response);
    setErrorMessage(errors?.join('; '));
    return;
  }

  const isFilledAllRequiredInput = () => {
    return fullName?.trim() !== '' 
    && mobileNumber?.trim() !== ''
    && email?.trim() !== '' && selectedRoles?.length > 0;
  }

  const handleChangeRoles = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedRoles(value);
  };

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );


  const checkNumber = () => {
    let result = telephoneRegex.test(mobileNumber) && mobileNumber !== '';
    setInvalidNumberFormat(!result);
  }

  return (
   <ThemeProvider theme={theme}>
      <CssBaseline />
      { loading ?  (<Loading />) :
      <>
        { isError &&
          <Popup 
            type={PopupType.ERROR}  
            content={errorMessage}
            flag={isError}
            duration={100000}
          />
        }
        { isSuccess &&
          <Popup 
            type={PopupType.SUCCESS}  
            content={labels.usermanager_adduser_success}
            flag={isSuccess}
            duration={100000}
          />
        }
      

        <Container component="form"  onSubmit={handleSubmit} sx={{ mt: 1, marginBottom: 60 }}>
          <PageHeaderSection 
            title={labels.usermanager_adduser_title}
            description={labels.usermanager_adduser_description}
          />
          <Paper elevation={4} component={Container}>
            <Grid container spacing={3} paddingBottom={20}>
              <Grid item xs={12} mb={2}>
                  <Typography variant="h4" fontWeight={600}>{labels.usermanager_adduser_subtittle}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label={labels.usermanager_adduser_input_fullname}
                  fullWidth
                  autoComplete="nome"
                  variant="standard"
                  value={fullName}
                  onChange={(e)=>setFullName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                
      <FormControl error={invalidNumberFormat} variant="standard" fullWidth>
        <InputLabel htmlFor="mobileNumber">{labels.usermanager_adduser_input_mobilenumber}</InputLabel>
        <Input
          id="mobileNumber"
          required
          name="mobileNumber"
          autoComplete="mobileNumber"
          variant="standard"
          type='text'
          value={mobileNumber}
          onChange={(e)=>setMobileNumber(e.target.value)}
          onBlur={checkNumber}
          aria-describedby="mobileNumber-error-text"
        />
        {invalidNumberFormat && 
        <FormHelperText id="mobileNumber-error-text" sx={{fontSize: '1.2rem'}}>Número de telemóvel inválido</FormHelperText>
        }
      </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label={labels.usermanager_adduser_input_email}
                  fullWidth
                  autoComplete="email"
                  variant="standard"
                  type='email'
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth sx={{minWidth: 120}}>
                  <InputLabel id="perfilLabel">{labels.usermanager_adduser_input_role}</InputLabel>
                  <Select
                    required
                    labelId="perfilLabel"
                    id="perfil"
                    label={labels.usermanager_adduser_input_role}
                    multiple
                    value={roles}
                    onChange={handleChangeRoles}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    >
                    {
                      roleList?.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          <Checkbox checked={roles.indexOf(role.name) > -1}
                            sx={{
                              [`&, &.${checkboxClasses.checked}`]: {
                                color: '#506E58',
                              },
                            }}
                          />
                          <ListItemText primary={role.description} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Box
            component="span"
            marginTop={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            paddingLeft={0}
          >
            <Button 
              variant="contained"
              type='submit' 
              sx={{height: 40, ml: 5, textTransform: 'none' , fontSize: '18px'}}
              size='medium'
              disabled={!isFilledAllRequiredInput() || loading}
              endIcon={<ArrowForwardIcon />}
              >
                {labels.usermanager_adduser_button_add}
            </Button>
          </Box>
        </Container>
      </>
    }
    </ThemeProvider>
  );
}
