import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../../components/ui/Footer';

import styles from './../../assets/css/signup.module.scss';
import logo from '../../assets/images/logo-login.png';
import labels from '../../utils/Labels.json';

import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Tooltip } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { UserStatus } from "../../utils/UserStatus";
import Popup from '../../components/ui/Popup';


import { isValidEmail, isValidPassword, isValidFormData } from '../../utils/CredentialsValidator';
import Loading from '../../components/ui/Loading';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import { jwtDecode } from "../../utils/TokenUtils";
import {encryptData, getEncryptedClientHeaderInfo} from '../../utils/SecurityUtils';
import {responsiveFontSizes} from '@mui/material/styles';
import { green } from '@mui/material/colors';



import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";




import api from '../../services/api';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import { PopupType } from '../../utils/PopupType';

const LOGIN_URL = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_SESSION_LOGIN}`;

export default function SignIn() {
  let emailRef = useRef();
  let passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [enableButton, setEnableButton]= useState(false);
  const { setAuth } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  
  const userRef = useRef();

  useEffect(() => {
    userRef?.current.focus();
  }, [])

  useEffect(() => {
    setErrorMessage('');
  }, [emailRef, passwordRef])

  const login = async () => {
    setLoading(true);

    try {

      const response = await api.post(LOGIN_URL,
        JSON.stringify({ 
          email: emailRef.current.value,
          password: passwordRef.current.value }),
        {
          headers: {'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo()}
        }
      );

      if (response.status !== HttpResponseStatus.OK) {
        return handleError(response);
      }
      
      mapSessionInfo(response.data);
      setLoading(false);
      localStorage.setItem('showLoginSuccess', true);
      
    } catch (err) {
      console.log("Error: ", err);
      handleError(err?.response);
    }
  }

  const handleError = (response) => {
    setEnableButton(false);
    setLoading(false);
    setIsError(true);
    if (response) {
      const errors = extractErrorMessagesFromResponse(response);
      setErrorMessage(errors?.join('; '));
  } else {
    setErrorMessage(labels.common_generic_error);
  }
    return;
  } 
    
  const handleSubmit = (event) => {
    event.preventDefault();
     
    if(isValidEmail(emailRef.current.value) && isValidPassword(passwordRef.current.value)) {
      login();
    }
  };


  const mapSessionInfo = (data) => {
    const session = jwtDecode(data.accessToken);
    const refreshToken = data.refreshToken;
    const accessToken = data.accessToken;

    const user = session?.user;
    const name = user?.fullName.split(' ')[0];
    const email = user?.email;
    const rolesList = user?.roles;
    const roles = [];
    
    rolesList.forEach(role => {
        roles.push(role.name);
    });
    
    localStorage.setItem('version', encryptData(refreshToken));
    localStorage.setItem("counter", encryptData(accessToken));

    setAuth({ email, name, roles, refreshToken, user });
    redirectUser(session);
  }

  const redirectUser = (userData) => {
    userData?.user?.status?.code === UserStatus.NEW ? 
      navigate(process.env.REACT_APP_ROUTE_USERS_CHANGE_INITIAL_PASSWORD) : 
      navigate('/');
  }

  let theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );
  theme = responsiveFontSizes(theme);

  const handleEmail = (e) => {
    e.preventDefault();
    setEnableButton(isValidFormData(emailRef.current.value, passwordRef.current.value));
  }

  const handlePassword = (e) => {
    e.preventDefault();
    setEnableButton(isValidFormData(emailRef.current.value, passwordRef.current.value));
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        { loading ? <Loading /> :
        <Grid container component="main" sx={{ paddingBottom:'80px !important' , marginBottom: '80px !important'}}>
          <Box component={Container} sx={{ flexGrow: 1 }}>
            <Grid container spacing={10} columns={16} paddingTop={10}>
              <Grid item xs={8} minWidth={420} margin={'auto'}>
                <Grid item marginTop={'80px'} className={styles.content_img}>
                  <img src={logo} alt="" className={styles.log_image} />
                </Grid>
              </Grid>
              <Grid item xs={8} minWidth={420} margin={'auto'} className={styles.content_data}>
                <Box component={Paper} elevation={10} padding={5} paddingTop={10} paddingBottom={10}>
                  <Avatar sx={{ m: 1, bgcolor: '#506E58', margin: 'auto'}}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5" textAlign={'center'}>
                    Login
                  </Typography>
                  <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                      ref={userRef}
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={handleEmail}
                      inputRef={emailRef}
                    />
                   
                    <TextField
                      variant="standard"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Palavra-passe"
                      id="password"
                      autoComplete="new-password"
                      onChange={handlePassword}
                      inputRef={passwordRef}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={!showPassword ? 'Ver palavra-passe' : 'Ocultar palavra-passe'}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{minLength: 8, maxLength: 30}}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                      disabled={!enableButton|| loading}
                    >
                      Entrar
                    </Button>
                    <Grid container>
                      <Grid item xs mt={6}>
                          <Link href="/utilizadores/recuperar-palavra-passe" variant="body1" sx={{ textDecoration: 'none', color: '#000'}}>
                          Esqueceu palavra-passe ?
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      }
      </>
    <Footer />
    { isError &&
      <Popup 
        type={PopupType.ERROR}  
        content={errorMessage}
        flag={isError}
        duration={5000}
      />
    }
    </ThemeProvider>
  );
}