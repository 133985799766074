export const extractErrorMessagesFromResponse = (response) => {
    console.log('response: ', response);
    let messages = [];
    const values = Object.values(response?.data?.errorMessages);
    values.map((message) => {
        message.map((error) => messages.push(error.userMessage))
    }
     );

   return messages;
}