import { Container, Grid, Stack, Typography } from '@mui/material';
import loading_img from '../../assets/icons/loading.svg';



export default function Loading() {
  return (
    <Container 
      sx={{
        position: 'absolute', 
        left: '50%', 
        top: '50%',
        transform: 'translate(-50%, -50%)', 
        zIndex: 111
        }}>
        <Stack spacing={2} alignItems={'center'} justifyContent={'center'}>
          <div>
            <img src={loading_img} alt='loading_img' />
          </div>
          <div>
            <Typography component="h1" variant="h5">
                Por favor aguarde...
            </Typography>
          </div>
        </Stack>
    </Container>
  )
}
