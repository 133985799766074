import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import unauthorizedIcon from '../../assets/images/unauthorized.png';

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <>
          <Container fixed>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}>
                <Grid marginTop={0}>
                  <Typography variant="h4" textAlign={'center'} sx={{fontWeight: 600, fontSize: '1.5rem !important'}}>
                    Não autorizado!
                  </Typography>
                  <img src={unauthorizedIcon} />
                  <Typography variant="h4" textAlign={'center'} sx={{fontWeight: 500, fontSize: '1.4rem !important'}} paddingTop={2} paddingBottom={2}>
                    Não tem permissões para aceder a página pretendida!
                  </Typography>
                </Grid>
                <Button
                  variant="outlined"
                  color='inherit'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={()=> goBack()}>
                  Voltar
                </Button>  
              </Grid>
            </Box>
          </Container>
        </>
       
      )
    }

export default Unauthorized