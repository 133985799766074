import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, Input, InputLabel, ListItemText, MenuItem, Modal, Select, Snackbar, TextField, ThemeProvider, Toolbar, Tooltip, checkboxClasses, createTheme, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState} from 'react';
import DataInfo from '../../components/ui/DataInfo';
import { getFormattedFullDate } from '../../utils/DateUtils';
import PageHeaderSection from '../../components/ui/PageHeaderSection';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import labels from '../../utils/Labels.json';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import useAuth from '../../hooks/useAuth';
import { green } from '@mui/material/colors';
import { PopupType } from '../../utils/PopupType';
import Popup from '../../components/ui/Popup';
import CachedIcon from '@mui/icons-material/Cached';
import CustomContainer from '../../components/ui/CustomContainer';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const URL_USERS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_USERS}`;


function Row(props) {
  const { row, roleList, setissuccess } = props;
  const [open, setOpen] = React.useState(false);
  const { auth } = useAuth();
  const [edit, setEdit] = useState(false);
  const [sameData, setSameData] = useState(true);

  const axiosPrivate = useAxiosPrivate();

  const [selectedRoles, setSelectedRoles] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserFullName, setSelectedUserFullName] = useState('');
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [selectedUserPhoneNumber, setSelectedUserPhoneNumber] = useState('');

  const [newUserFullName, setNewUserFullName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');

  const [roles, setRoles] = useState([]);

  const [invalidNumberFormat, setInvalidNumberFormat] = useState(false);
  const telephoneRegex = /^(\+351|\+351 |00351|00351 )?[9][0-9]{8}$/;

  useEffect(()=> {
    const rolesTemp = []; 
    row?.roles?.map(role => rolesTemp.push(role.name));
    setRoles(rolesTemp);
  }, [])

  const handleEdit= () => {
    setEdit((prev) => !prev);
    if (selectedUserId) {
      updateUserData();
    }
  }

  const handleChangeRoles = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setSelectedRoles(value);
    setSameData(false);  
  };


  const handleChangeUserFullName = (e) => {
    setNewUserFullName(e.target.value);
    setSameData(e.target.value===selectedUserFullName);
  }

  const handleChangeUserEmail = (e) => {
    setNewUserEmail(e.target.value);
    setSameData(e.target.value===selectedUserEmail);
  }

  const handleChangeUserPhoneNumber = (e) => {
    setNewUserPhoneNumber(e.target.value);
    setSameData(e.target.value===selectedUserPhoneNumber);
  }

  const changeUserNameAndId = (user)=> {
    setSelectedUserFullName(user.fullName);
    setSelectedUserId(user.id);
    setSelectedUserEmail(user.email);
    setSelectedUserPhoneNumber(user.mobileNumber);

    setNewUserFullName(user.fullName);
    setNewUserEmail(user.email);
    setNewUserPhoneNumber(user.mobileNumber);
  }

  const handleCancel = () => {
    setEdit(false);
  }

  let theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const updateUserData= async () => {

    let user = {};

    if (newUserFullName) {
      user.fullName= newUserFullName;
    }
    if (newUserEmail){
      user.email= newUserEmail;
    }

    if (newUserPhoneNumber){
      user.mobileNumber = newUserPhoneNumber;
    }

    if (selectedRoles){
      user.roles = selectedRoles;
    }
    
    try {
      setissuccess(false);
      const response = await axiosPrivate.patch(URL_USERS+ `/${selectedUserId}`, 
      JSON.stringify(user));

      if (response.status !== HttpResponseStatus.OK) {
        return handleError(response);
      }
      setissuccess(true);

    } catch (err) {
      console.log("Error: ", err);
    }
  }


  const handleError = (response) => {
    const error = response?.status;

    if (!response || error === HttpResponseStatus.NOT_FOUND) {
      //setErrorMessage(labels.common_generic_error);
      return;
    } 

    //setErrorMessage(errors?.join('; '));
    return;
  }

  const checkNumber = () => {
    let result = telephoneRegex.test(newUserPhoneNumber) && newUserPhoneNumber !== '';
    setInvalidNumberFormat(!result);
  }
 
  return (
    <ThemeProvider theme={theme}>
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Typography variant="h6" gutterBottom>
            {row.fullName}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {row.email}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6" gutterBottom>
            {row.roles?.[0]?.description}
          </Typography>
        </TableCell>
        <TableCell>
        <Tooltip title={open ? 'Ver menos': 'Ver mais'}>
            <IconButton
              aria-label="ver detalhes"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>

          {
            auth?.roles?.includes('ADM') &&
            <Tooltip title="Editar">
              <IconButton
                size="small"
                sx={{ml: 2}}
                onClick={() => { handleEdit(); changeUserNameAndId(row); }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4} minWidth={'md'}>
                <DataInfo label="Estado:" content={row.status.description} />
                <DataInfo label="Nº telemóvel:" content={row.mobileNumber} />
              </Grid>
              <Grid item xs={4}>
                <DataInfo label="Data criação:" content={getFormattedFullDate(row.creationDate)} />
                <DataInfo label="Data ativação:" content={getFormattedFullDate(row.activationDate, null, true)} />
              </Grid>
              <Grid item xs={4}>
                <DataInfo label="Perfil:" content={ row.roles?.map((role) => role.description).join(', ') } />
              </Grid>
            </Grid>
          </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        fullScreen={fullScreen}
        open={edit}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{pt: 3}}>
          {`Alterar dados do utilizador ${selectedUserFullName}`}
        </DialogTitle>
        <DialogContent>
        <FormControl variant="standard" fullWidth sx={{minWidth: 400}}>
          <InputLabel id="perfilLabel">{labels.usermanager_adduser_input_role}</InputLabel>
            <Select
              required
              labelId="perfilLabel"
              id="perfil"
              label={labels.usermanager_adduser_input_role}
              multiple
              value={roles}
              onChange={handleChangeRoles}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              sx={{mb: 3}}
              >
              {
                roleList?.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    <Checkbox checked={roles.indexOf(role.name) > -1}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: '#506E58',
                        },
                      }}
                    />
                    <ListItemText primary={role.description} />
                  </MenuItem>
                ))
              }
            </Select>
            <Grid item xs={12} sm={6} sx={{mb: 3}}>
              <TextField
                required
                id="name"
                name="name"
                label={'Nome completo'}
                fullWidth
                autoComplete="nome"
                variant="standard"
                value={newUserFullName}
                onChange={handleChangeUserFullName}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{mb: 3}}>
              <TextField
                type='email'
                required
                id="email"
                name="email"
                label={'Email'}
                fullWidth
                autoComplete="nome"
                variant="standard"
                value={newUserEmail}
                onChange={handleChangeUserEmail}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{mb: 3}}>
              <FormControl error={invalidNumberFormat} variant="standard" fullWidth>
                <InputLabel htmlFor="mobile">{labels.usermanager_adduser_input_mobilenumber}</InputLabel>
                <Input
                  id="mobile"
                  required
                  label="Nº telemóvel"
                  name="mobile"
                  autoComplete="mobileNumber"
                  variant="standard"
                  value={newUserPhoneNumber}
                  onChange={handleChangeUserPhoneNumber}
                  onBlur={checkNumber}
                  aria-describedby="mobileNumber-error-text"
                />
                {invalidNumberFormat && 
                <FormHelperText id="mobileNumber-error-text" sx={{fontSize: '1.2rem'}}>Número de telemóvel inválido</FormHelperText>
                }
              </FormControl>
            </Grid>
            </FormControl>
            </DialogContent>
              <DialogActions sx={{pr: 2}}>
                <Button autoFocus onClick={handleCancel}
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                >
                  Sair
                </Button>
                <Button onClick={handleEdit} autoFocus
                  sx={{mt: 3, ml: 1, mb: 2 , height: 40, textTransform: 'none' , fontSize: '18px'}}
                  disabled={sameData || invalidNumberFormat}
                >
                  Alterar
                </Button>
              </DialogActions>
          </Dialog>
    </React.Fragment>
    </ThemeProvider>
  );
}

export default function ListUsers() {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const axiosPrivate = useAxiosPrivate();
  const [roleList, setRoleList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [users, setUsers] = useState();
  const URL_ROLES = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_ROLES}`;

  const fetchUsers = async () => {

    try {
      setLoading(true);
      setUsers([]);
      const response = await axiosPrivate.get(URL_USERS +'?size=9999&v=' + new Date().valueOf());

      if (response.status !== HttpResponseStatus.OK) {
        return handleError(response);
      }

      setLoading(false);
      setUsers(response.data.content);

    } catch (err) {
      setLoading(false);
      console.log("Error: ", err);
    }
  }
  
  useEffect(() => {
    fetchUsers();
  },[]);

  useEffect(() => {
    const fetchRoles = async () => {
    try {
      const response = await axiosPrivate.get(URL_ROLES);
      
      if (response.status !== HttpResponseStatus.OK) {
        handleError(response);
      }

      setRoleList(response.data.lovs);
    } catch (err) {
      handleError(err?.response);
    }
  }

  fetchRoles();
},[])


const refreshData = (e) => {
  e.preventDefault();
  fetchUsers();
}


  const handleError = (response) => {
    const error = response?.status;
    setIsError(true);

    if (!response || error === HttpResponseStatus.NOT_FOUND) {
      setErrorMessage(labels.common_generic_error);
      return;
    } 

    const errors = extractErrorMessagesFromResponse(response);
    setErrorMessage(errors?.join('; '));
    return;
  }

  return (
    <CustomContainer
      content={
        <>
      <PageHeaderSection 
        title='Consultar utilizadores'
        description='Lista de utilizadores registados no sistema.'
      />
    {
      loading ? 
      ( 
        <Typography variant="h6"  textAlign={'center'} gutterBottom>
          Por favor aguarde...
        </Typography>
      )
    : 


    <>
      <Paper elevation={4} component={Container}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%', paddingTop: '10px' }}
            variant="h4"
            id="tableTitle"
            component="div"
            fontWeight={'600'}
          >
            Utilizadores
          </Typography>
          {users &&
            <Tooltip title="Atualizar" style={{marginRight: 10, marginTop: 10}} >
              <IconButton
                onClick={refreshData}
              >
                <CachedIcon />
            </IconButton>
            </Tooltip>
          }
        </Toolbar>
    { !users || users?.length < 1 ? 
    <>
    <Typography variant="h6" gutterBottom marginBottom={5}>
        Sem resultados para apresentar
    </Typography>
    
    </> :

      <TableContainer sx={{marginTop: 6}}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5" gutterBottom fontWeight={'600'}>Nome</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" gutterBottom fontWeight={'600'}>Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5" gutterBottom fontWeight={'600'}>Perfil</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map(user => (
              <Row row={user} key={user.id} roleList={roleList} setissuccess={setIsSuccess} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    }
    </Paper>
  </>
  }

    <Container>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        open={isError}
        message={errorMessage}
      />
    </Container>

    { isSuccess &&
          <Popup 
            type={PopupType.SUCCESS}  
            content={labels.usermanager_updateuser_success}
            flag={isSuccess}
            duration={5000}
          />
        }
    </>
    }
  />

  );
}
