import CryptoJS from 'crypto-js';
import { Constants } from './Constants';

const secret = process.env.REACT_APP_API_SECRET_CLIENT_KEY;

export const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), Constants.SECRET).toString();
    return encryptedData;
}

export const decryptData = (data) => {
    const decryptedBytes = CryptoJS.AES.decrypt(data, Constants.SECRET);
    const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
}

export const encryptClientSecret = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString();
}

export const getEncryptedClientHeaderInfo = () => {
    return encryptClientSecret(process.env.REACT_APP_CLIENT_VALUE);
  };
  