import { Grid, Typography } from '@mui/material';

interface IProps {
    title: string;
    description: string;
}

export default function PageHeaderSection({title, description}: IProps) {
  return (
    <>
        <Grid container>
            <Typography variant="h4" fontWeight={'600'} sx={{fontSize: '1.5rem !important'}}>
                {title}
            </Typography>
        </Grid>
        <Grid item xs={12} mt={'2px'} mb={10}>
            <Typography sx={{marginTop: 2, fontSize: '1.1rem !important'}}>
                {description}
            </Typography>
        </Grid>
    </>
  )
}
