import Box from '@mui/material/Box';
import Copyright from './../ui/Copyright';
import styles from './../../assets/css/footer.module.scss';
import { Container, CssBaseline, Typography } from '@mui/material';


export default function Footer() {
  return (
    /*
    <Box component="footer" className={styles.footer}>
        <Copyright />
    </Box>

    */

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '10vh',
      }}
    >
      <CssBaseline />
      
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto'
        }}
        className={styles.footer}
      >
        <Container className={styles.footer_content}>
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
}