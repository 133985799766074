import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';



function Header(props) {
  const { onDrawerToggle } = props;
  const [greetMessage, setGreetMessage] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=> {
    greetUser();
  })

  const greetUser=()=> {
    const curHr = new Date().getHours();

    if (curHr < 12) {
      setGreetMessage("Bom dia");
    } else if (curHr < 18) {
      setGreetMessage("Boa tarde");
    } else {
      setGreetMessage("Boa noite");
    }
  }


  (()=>{
    setInterval(()=> {
      greetUser();
      setCurrentTime(new Date().toLocaleTimeString());
    },1000);
  })();

  const navigate = useNavigate();

  const URL_LOGOUT = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_SESSION_LOGOUT}`;

  const logout = async () => {
  
      try {
        setLoading(true);
        const response = await axiosPrivate.post(URL_LOGOUT);
        setLoading(false);
        localStorage.clear();
        setAuth({});
        navigate('/login');
        
      } catch (error) {
        setLoading(false);
        localStorage.clear();
        setAuth({});
        navigate('/login');
      }
  };

  
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Typography
              >
                {greetMessage}{', '}{ auth.name }
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Sem notificações">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
            <Tooltip title="Definições da sessão">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 ,  p: 0.5}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                color="inherit"
              >
              <AccountCircle />
              </IconButton>
            </Tooltip>
            </Grid>
            <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Terminar sessão
        </MenuItem>
      </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;