import React, { useState, useRef } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography, Container, InputLabel, Input, FormHelperText, FormControl } from '@mui/material';
import styles from './../../assets/css/signup.module.scss';
import logo from './../../assets/images/logo-portal-consular.jpg';
import Footer from '../../components/ui/Footer';
import { isValidEmail } from '../../utils/CredentialsValidator';
import Loading from '../../components/ui/Loading';
import { useNavigate } from 'react-router';
import { green } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import labels from '../../utils/Labels.json';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';
import Popup from '../../components/ui/Popup';
import { PopupType } from '../../utils/PopupType';


const URL_RESET_PASSWORD = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_USERS_RESET_PASSWORD}`;


export default function ResetPassword() {
  let emailRef = useRef();
  let mobileNumberRef = useRef();
  const[isError, setIsError] = useState(false);
  const[loading, setLoading] = useState(false);
  const[errorMessage, setErrorMessage] = useState(false);
  const[isSuccess, setIsSuccess] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const [invalidNumberFormat, setInvalidNumberFormat] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const telephoneRegex = /^(\+351|\+351 |00351|00351 )?[9][0-9]{8}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const navigate = useNavigate();

  const resetPassword = React.useCallback(() => {

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo() },
      body: JSON.stringify({ email: emailRef.current.value, mobileNumber: mobileNumberRef.current.value })
    };

    const controller = new AbortController();
    const signal = controller.signal;
    setLoading(true);
    fetch(URL_RESET_PASSWORD, requestOptions, {signal})
        .then(async response => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
           
            if (!response.ok) {
              return handleError(data);
            }

            setLoading(false);
            setIsSuccess(true);
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            return;
          }
          handleError(error?.response);
        })

        return () => {
          controller.abort();
        }
     });

  const handleError = (response) => {
    setEnableButton(false);
    setLoading(false);
    setIsError(true);
    emailRef.current=null;
    mobileNumberRef.current=null;

    if (response) {
      const errors = extractErrorMessagesFromResponse(response);
      setErrorMessage(errors?.join('; '));
    } else {
      setErrorMessage(labels.common_generic_error);
    }
    return;
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
     
    if(true || isValidEmail(emailRef.current.value)) {
      resetPassword();
    }
  };

  const handleEmail=(e)=> {
    e.preventDefault();
    checkEnableButton();
  }

  const handleMobileNumber=(e)=> {
    e.preventDefault();
    checkEnableButton();
  }

  const checkEnableButton = () => {
    setEnableButton(isValidEmail(emailRef.current.value) && emailRef.current.value && mobileNumberRef.current.value);
  }

  const redirectToLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  }

  const checkNumber = () => {
    const mobileNumber = mobileNumberRef?.current?.value; 
    let result = telephoneRegex.test(mobileNumber) && mobileNumber !== '';
    setInvalidNumberFormat(!result);
  }

  const checkEmail = () => {
		setInvalidEmail(isNotEmpty(emailRef.current.value) && !emailRegex.test(emailRef.current.value));
	};

  const isNotEmpty = (input) => {
		return input?.length > 0;
	};

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />  

        {
        loading ? <Loading /> :
        <>
          <Grid 
            container
            spacing={0}
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            
          >
            <Grid item xs={3} marginTop={'80px'}>
                <img src={logo} alt="" className={styles.center_image} />
            </Grid>
          <Grid>
            <Typography variant="h6" mb={5} mt={5}>
              Para recuperar a sua palavra-passe, por favor insira o endereço de email registado no sistema. 
            </Typography>
          </Grid>
          <Container sx={{ mt: 1, marginBottom: 60 }}>
          
          { isSuccess ?
            <Grid container spacing={3} component={Paper} elevation={2} paddingLeft={5} paddingRight={10} paddingBottom={20} paddingTop={5}>
              <Typography variant="h6" mb={5} mt={5}>
                Se o endereço de email estiver registado no sistema, irá receber a sua nova palavra-passe.
              </Typography>
              <Typography variant="h6" mb={5} mt={2}>
                Para fazer login utilizando as credenciais enviadas {' '}
                <Link onClick={redirectToLogin}>clique aqui</Link>
              </Typography>
            </Grid> :
          
          <>
          <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 1}}>
            <Grid container spacing={3} component={Paper} elevation={2} paddingLeft={5} paddingRight={10} paddingBottom={20} paddingTop={5}>
              <Grid item xs={12} sm={6}>
                <FormControl error={invalidEmail} variant='standard' fullWidth>
                  <TextField
                    type='email'
                    required
                    label="Email"
                    fullWidth
                    autoComplete="nome"
                    variant="standard"
                    onChange={handleEmail}
                    onKeyUp={checkEmail}
                    inputRef={emailRef}
                  />
                   {invalidEmail && 
                    <FormHelperText id="invalidEmail-error-text" sx={{fontSize: '1.2rem'}}>
                      Formato de email inválido
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl error={invalidNumberFormat} variant="standard" fullWidth>
                  <InputLabel htmlFor="mobileNumber">Nº telemóvel</InputLabel>
                  <Input
                    id="mobileNumber"
                    required
                    name="mobileNumber"
                    autoComplete="mobileNumber"
                    variant="standard"
                    type='text'
                    inputRef={mobileNumberRef}
                    onChange={handleMobileNumber}
                    onKeyUp={checkNumber}
                    aria-describedby="mobileNumber-error-text"
                  />
                  {invalidNumberFormat && 
                    <FormHelperText id="mobileNumber-error-text" sx={{fontSize: '1.2rem'}}>
                      Número de telemóvel inválido
                    </FormHelperText>
                  }
                </FormControl>
              </Grid>
            </Grid>
            <Box
              marginTop={5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
            <Button 
              variant="outlined"
              color="error"
              sx={{ml: '-20px',  height: 40, textTransform: 'none' , fontSize: '18px'}}
              onClick={redirectToLogin}
            >
              Cancelar
            </Button>
            <Button 
              variant="contained"
              type='submit' 
              sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
              disabled={!enableButton || loading || invalidNumberFormat}
            >
              Recuperar palavra-passe
            </Button>
          </Box>
          </Box>
          </>
        }
        </Container>
        <Popup
          flag={isError}
          type={PopupType.ERROR}
          content={errorMessage}
          duration={10000}
        />
      </Grid>
    <Footer />
  </>
  }
  </ThemeProvider>
  )
}
