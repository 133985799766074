export class AddUserParams {
    fullName: string;
    mobileNumber: string;
    email: string;
    roles: string[];

    constructor(fullName: string, mobileNumber: string, email: string, roles: string[]) {
        this.fullName = fullName;
        this.mobileNumber = mobileNumber;
        this.email = email;
        this.roles = roles;
    }
}