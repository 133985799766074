import { Route, Routes } from "react-router-dom";
import PageNotFound from './pages/common/PageNotFound';
import './assets/css/global.module.scss';


import Dashboard from "./pages/common/Dashboard";
import SignIn from "./pages/users/SignIn";
import ResetPassword from "./pages/users/ResetPassword";
import ChangeInitialPassword from "./pages/users/ChangeInitialPassword";
import PersistLogin from "./context/PersistLogin";

import RequireAuth from "./pages/common/RequireAuth";
import Layout from "./pages/common/Layout";
import Unauthorized from "./pages/common/Unauthorized";



const ROLES = {
  'Adm': 'ADM',
  'Ges': 'GES',
  'Func': 'FUN',
  'Col': 'COL'
}

function App() {
  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Adm, ROLES.Ges, ROLES.Func, ROLES.Col]} />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/utilizadores/alterar-palavra-passe-inicial" element={<ChangeInitialPassword />} />
          </Route>
        </Route>

        {/* Public routes */}
        <Route path="/login" element={<SignIn />} />
        <Route path="/nao-autorizado" element={<Unauthorized />} />
        <Route path="/utilizadores/recuperar-palavra-passe" element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
