import axios from '../services/api';
import useAuth from './useAuth';
import { jwtDecode } from '../utils/TokenUtils';
import { encryptData, decryptData, getEncryptedClientHeaderInfo } from '../utils/SecurityUtils';

const useRefreshToken = () => {
    const {setAuth } = useAuth();

    const refresh = async () => {
        const ref_token = localStorage.getItem('version');

        const response = await axios.get('/sessions/refresh', {
            headers: { 'Content-Type': 'application/json',
                        "Authorization": `Bearer ${decryptData(ref_token)}`,
                        'x-client-id': getEncryptedClientHeaderInfo()
                    }
                }
            );
       

        const data = response.data;

        if (data) {
            const session = jwtDecode(data?.accessToken);
        const refreshToken = data.refreshToken;
        const accessToken = data.accessToken;

        const user = session?.user;
        const name = user?.fullName.split(' ')[0];
        const email = user?.email;
        const rolesList = user?.roles;
        const roles = [];
        rolesList.forEach(role => {
            roles.push(role.name);
        });
        
        localStorage.setItem('version', encryptData(refreshToken));
        localStorage.setItem('counter', encryptData(accessToken))

        setAuth(prev => {
            return { ...prev,email: email, 
                        name:name, 
                        roles:roles, 
                        refreshToken: data.refreshToken,
                        accessToken: data.accessToken,
                        user: user
                    }
        });
        return data?.refreshToken;

        }
        return;

        
    }
    return refresh;
};

export default useRefreshToken;