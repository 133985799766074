import { useRef, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Container, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import styles from './../../assets/css/signup.module.scss';
import logo from './../../assets/images/logo-portal-consular.jpg';
import Footer from '../../components/ui/Footer';

import { useNavigate } from 'react-router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Loading from '../../components/ui/Loading';
import { green } from '@mui/material/colors';
import {HttpResponseStatus} from '../../models/common/HttpResponseStatus';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import Popup from '../../components/ui/Popup';
import { PopupType } from '../../utils/PopupType';
import labels from '../../utils/Labels.json';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import { isValidPassword } from '../../utils/CredentialsValidator';
import PageHeaderSection from '../../components/ui/PageHeaderSection';



export default function ChangeInitialPassword() {
  const currentPasswordRef = useRef("");
  const newPasswordRef = useRef("");
  const confirmationPasswordRef = useRef("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [enabled, setEnable] = useState(false);
  const { setAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  
  const URL_LOGOUT = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_SESSION_LOGOUT}`;
  const URL_CHANGE_PASSWORD = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_USERS_CHANGE_PASSWORD}`;

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

  const changePassword = async () => {
    try {
      setLoading(true);
      const response = await axiosPrivate.put(URL_CHANGE_PASSWORD, 
        { 
          currentPassword: currentPasswordRef.current.value,
          newPassword: newPasswordRef.current.value
        });
      
      if (response.status !== HttpResponseStatus.OK) {
        return handleError(response);
      }

      setLoading(false);
      setSuccess(true);
      setErrorMessage(false);
      setErrorMessage('');
      
    } catch (error) {
       /*Expired token then force logout*/
       if (error.response.status === HttpResponseStatus.UNAUTHORIZED) {
        localStorage.clear();
        navigate('/login');
        return;
      } else {
        handleError(error?.response)
      }
      setLoading(false);
    }   
  }

     

  
  const logout = async () => {
  
    try {
      setLoading(true);
      const response = await axiosPrivate.post(URL_LOGOUT);
      setLoading(false);
      localStorage.clear();
      setAuth({});
      navigate('/login');
      
    } catch (error) {
      setLoading(false);
      localStorage.clear();
      setAuth({});
      navigate('/login');
    }
};


  const handleSubmit = (event) => {
    event.preventDefault();

    const currentPass = currentPasswordRef?.current?.value;
    const newPass = newPasswordRef?.current?.value;
    const confirmationPass = confirmationPasswordRef?.current?.value;
  
    if (newPass !== confirmationPass) {
      setIsError(true);
      setErrorMessage('A nova palavra-passe e a palavra-passe de confirmação devem ser iguais');
      return;
    }
    else if (!currentPass || !newPass || !confirmationPass) {
      setIsError(true);
      setErrorMessage('Todos os campos são de preenchimento obrigatório');
      return;
    } else {
      changePassword();
    }
  };

  const checkValidity = () => {
    const currentPass = currentPasswordRef.current.value;
    const newPass = newPasswordRef.current.value;
    const confirmationPass = confirmationPasswordRef.current.value;
    setEnable(isValidPassword(currentPass) && 
      isValidPassword(newPass) && 
      isValidPassword(confirmationPass) && 
      newPass === confirmationPass);
  }

  const redirectToLogin = (e) => {
    e.preventDefault();
    logout();
  }

  const redirectToHome = (e) => {
    e.preventDefault();
    navigate('/');
  }

  const handleError = (response) => {
    setSuccess(false);
    setIsError(true);
    if (response) {
				const errors = extractErrorMessagesFromResponse(response);
				setErrorMessage(errors?.join('; '));
		} else {
			setErrorMessage(labels.common_generic_error);
		}
		return;
  }

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmationPassword = () => {
    setShowConfirmationPassword(!showConfirmationPassword);
  };

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );

  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container sx={{ mt: 1, marginBottom: 20 }}>
        <Grid 
          container
          spacing={0}
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          marginBottom={'60px'}
          
        >
          <Grid item xs={3} marginTop={'60px'}>
              <img src={logo} alt="" className={styles.center_image} />
          </Grid>
        </Grid>
    
        { success ? 
        <>

      <Box sx={{ mt: 10, marginBottom: 6 }}>
          <Grid container spacing={3} component={Paper} elevation={2} paddingLeft={5} paddingRight={10} paddingBottom={20} paddingTop={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" mb={5} sx={{fontWeight: 600}}>
              A sua palavra-passe foi atualizada com sucesso!
            </Typography>
              <Button
                variant="outlined"
                component="label"
                sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
                onClick={redirectToHome}
              >
                Aceder à área pessoal
              </Button>
            </Grid>
          </Grid>
        </Box>

        
        </> :



        <>
        { loading &&<Loading />}
          <Container component="main" onSubmit={handleSubmit} sx={{ mb: 4}}>
          <PageHeaderSection 
            title='Alterar palavra-passe inicial'
            description='Para a primeira utilização do portal, por favor altere a sua palavra-passe.'
          />
          <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} elevation={10}>

            <Grid container spacing={3} marginTop={1} marginBottom={5} sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pb: 10}}>
            <Grid item xs={12} sm={6} >
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Palavra-passe atual"
                  id="password"
                  autoComplete="new-password"
                  inputRef={currentPasswordRef}
                  onChange={checkValidity}
                  type={showCurrentPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={!showCurrentPassword ? 'Ver palavra-passe' : 'Ocultar palavra-passe'}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            edge="end"
                          >
                            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{minLength: 8, maxLength: 30}}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Nova palavra-passe"
                id="password"
                autoComplete="new-password"
                inputRef={newPasswordRef}
                onChange={checkValidity}
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={!showNewPassword ? 'Ver palavra-passe' : 'Ocultar palavra-passe'}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                inputProps={{minLength: 8, maxLength: 30}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                label="Confirmação nova palavra-passe"
                autoComplete="new-password"
                inputRef={confirmationPasswordRef}
                onChange={checkValidity}
                type={showConfirmationPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={!showConfirmationPassword ? 'Ver palavra-passe' : 'Ocultar palavra-passe'}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmationPassword}
                          edge="end"
                        >
                          {showConfirmationPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                inputProps={{minLength: 8, maxLength: 30}}
              />
            </Grid>
          </Grid>
          <Alert
              variant='outlined'
              severity='warning'
              sx={{ marginBottom: '40px', marginTop: '4px' }}
            >
              <AlertTitle sx={{ fontSize: '1.6rem' }}>
                A palavra-passe deve ter entre 8 e 30 caracteres.
              </AlertTitle>
            </Alert>
        </Paper>
        <Box
          marginTop={5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button variant="outlined" color="error"
            sx={{height: 40, textTransform: 'none' , fontSize: '18px'}}
            onClick={redirectToLogin}
          >
            Cancelar
          </Button>
          <Button variant="contained" type='submit' 
            sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
            onClick={handleSubmit}
            disabled={!enabled || loading}
            >
              Alterar palavra-passe
          </Button>
        </Box>
        </Container>
        
        </>
        }

        { isError &&
          <Popup 
            type={PopupType.ERROR}  
            content={errorMessage}
            flag={isError}
            duration={10000}
          />
        }
      </Container>
      <Footer />
    </ThemeProvider>
  )
}
