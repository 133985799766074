import * as React from 'react';
import {ThemeProvider, CssBaseline, createTheme} from '@mui/material';
import { Container, Paper, Grid } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import DataInfo from '../../components/ui/DataInfo';
import { green } from '@mui/material/colors';
import PageHeaderSection from '../../components/ui/PageHeaderSection';
import { HttpResponseStatus } from '../../models/common/HttpResponseStatus';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import { getFormattedFullDate } from '../../utils/DateUtils';

const URL_USERS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_USERS}`;




export default function Settings() {
  const [value, setValue] = React.useState(0);
  const { auth } = useAuth();
  const [user, setUser] = React.useState({}); 

  const roles = [];

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  React.useEffect(()=> {
    getUserDetails(auth?.user?.id);
  }, [])

  auth?.user?.roles?.map(role => {
    if (!roles.includes(role)) {
      roles.push(role.description)
    };
  });

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );

  


  const getUserDetails = async (userId) => {
    try {

      const response = await axiosPrivate.get(URL_USERS + `/${userId}`);
     
      if (response.status !== HttpResponseStatus.OK) {
        return Promise.reject(response?.status);
      }
      
      setUser(response.data.user);

    } catch (err) {
      
      /*Expired token then force logout*/
    if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
      localStorage.clear();
      navigate('/login');
    }
    }
  }

  

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" sx={{ mb: 4 }}>
        <PageHeaderSection 
          title='Detalhes da conta'
          description='Informações sobre a sua conta.'
        /> 
        <Paper elevation={2} component={Container}>
        <Container sx={{ py: 8 }}>
            <Grid container columns={{ xs: 4, md: 12 }} sx={{paddingBottom: 5}}>
              <Grid item xs={6}>
                <DataInfo label="Nome completo:" content={user.fullName} />
                <DataInfo label="Perfil:" content={roles?.join(", ")} />
                <DataInfo label="Estado:" content={auth.user.status.description} />
                <DataInfo label="Nº telemóvel:" content={auth.user.mobileNumber} />
              </Grid>
              <Grid item xs={6}>
                <DataInfo label="Data criação:" content={getFormattedFullDate(user.creationDate)} />
                <DataInfo label="Data ativação:" content={getFormattedFullDate(user.activationDate)} />
                <DataInfo label="Email:" content={user.email} />
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}