import React, { useState, useEffect } from 'react';
import 'dayjs/locale/pt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { getEncryptedClientHeaderInfo } from '../../utils/SecurityUtils';
import { getFormattedDateAndHours, getFormattedFullDay, getLastDayOfMonth } from '../../utils/DateUtils';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Container, Divider, Grid, List, Paper, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { green } from '@mui/material/colors';
import CustomContainer from '../../components/ui/CustomContainer';
import PageHeaderSection from '../../components/ui/PageHeaderSection';
import DataInfo from '../../components/ui/DataInfo';

export default function ListTimeSlots() {
	const [timeSlotList, setTimeSlotList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [initialState, setInitialState] = useState(false);
	const [selectedDate,setSelectedDate] = useState('');

	const URL_TIME_SLOTS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_TIME_SLOTS}`;

	useEffect(() => {
		setInitialState(true);
	}, []);

	const handleSchedulingDate = (newDate) => {
		setInitialState(false);
		const selected = newDate?.format('YYYY-MM-DD');
		setSelectedDate(newDate);

		if (selected) {
			getSlotTimeByDate(selected);
		}
	};


	const reqOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'x-client-id': getEncryptedClientHeaderInfo() },
	};

	const getSlotTimeByDate = React.useCallback((date) => {
		let tempTimeSlotList = [];
        setLoading(true);
        setTimeSlotList([]);
		fetch(`${URL_TIME_SLOTS}?isBackOffice=1&pageSize=9999&beginTimeSlotDate=${date}&endTimeSlotDate=${getLastDayOfMonth(date)}&orderColumn=beginDate&direction=DESC&v=${new Date().valueOf()}`,
		reqOptions)
			.then(async (response) => {
				const isJson = response.headers.get('content-type')?.includes('application/json');
				const resp = isJson && (await response.json());

				if (!response.ok) {
					return handleError(response, resp);
				}

				const slots = resp?.content;
				slots?.forEach(slot => {
                    tempTimeSlotList.push(slot);
                });

                setTimeSlotList(tempTimeSlotList);  
                setLoading(false);
                setInitialState(false);   
			})
			.catch((error) => {
				setLoading(false);
				console.error('There was an error!', error);
			});
	});

	const handleError = (response, data) => {
		const error = (data && data.message) || response.status;
		return Promise.reject(error);
	};

	let theme = createTheme(
		{
		  palette: {
			primary: {
			  main: '#506E58',
			},
			secondary: {
			  main: green[500],
			},
		  },
		}
	  );
	  theme = responsiveFontSizes(theme);

	return (
		<ThemeProvider theme={theme}>
			<CustomContainer
				content={
			<>
				<PageHeaderSection 
				title='Listar faixas horárias'
				description='Lista de faixas horárias para o serviço de agendamentos.'
				/>
		<Paper elevation={2} component={Container}>
			<Typography
				variant="h4"
				component="div"
				fontWeight={'600'}
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					pt: 5
				}}
			>
				Dados das faixas horárias
			</Typography>
		<React.Fragment>
			<Container sx={{marginTop: '20px'}}>
				<h2 style={{paddingBottom: '20px'}}>Por favor, selecione mês e o ano pretendido.</h2>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
				<DatePicker
					value={selectedDate}
					views={['month', 'year']}
					onAccept={(newDate) => handleSchedulingDate(newDate)}
					label='Mês e ano'
					slotProps={{
						textField: {
							fullWidth: true,
							variant: 'standard',
							required: true,
						},
					}}
					sx={{
						'& .PrivatePickersYear-yearButton': { fontSize: '1.8rem'},
					}}
				/>
				</LocalizationProvider>

				<Divider sx={{marginTop: '20px'}} />

				<Grid padding={3}>
					{loading ? (
						<Grid>
							<p style={{ marginBottom: 5 }}>Por favor aguarde...</p>
						</Grid>
					) : (
					<>
					
						<Grid style={{paddingTop: '20px'}}>
							{!initialState && timeSlotList?.length > 0 && (
							<p style={{ marginBottom: '20px' }}>
								Dias com as faixas horárias configuradas:
							</p>
						)}
						<div>
							<List>
								{
									timeSlotList?.map((timeSlot, index) => (
										<div style={{marginBottom: '10px'}} key={index}>
											<Accordion defaultExpanded={index===0} sx={{marginBottom: '5px'}} key={index}>
												<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
												>
													<b>{getFormattedFullDay(timeSlot.beginDate)}</b>
												</AccordionSummary>
												<AccordionDetails>
													{ timeSlot?.hours?.map( (slot) => (
														<Chip
															sx={{
																width: '150px !important',
																margin: '10px !important'
															}}
															key={slot.id}
															label={slot.value}
															id={slot.id}
															className='slot_time'
															variant='outlined'
														/>   
														))
													}
												</AccordionDetails>
												<Box component={Container} sx={{ flexGrow: 1, pb: 6 }}>
												<Typography variant="h6" sx={{fontWeight: 600, pr: { xs: 1, sm: 1 }}} gutterBottom marginBottom={3} paddingTop={6}>
													Detalhes da configuração
												</Typography>
												<Grid container spacing={2} paddingBottom={2}>
													<Grid item xs={6}>
														<DataInfo label="Hora início trabalho" content={timeSlot?.beginWorkTime} />
														<DataInfo label="Hora fim trabalho" content={timeSlot?.endWorkTime} />
														<DataInfo label="Hora início almoço" content={timeSlot?.beginLunchTime} />
														<DataInfo label="Hora fim almoço" content={timeSlot?.endLunchTime} />
													</Grid>
													<Grid item xs={6} minWidth={'md'}>
														<DataInfo label="Intervalo atendimento" content={timeSlot?.serviceInterval} />
														<DataInfo label="Nº colaboradores disponíveis" content={timeSlot?.availableCollaboratorNumber} />
														<DataInfo label="Efetuada em" content={getFormattedDateAndHours(timeSlot?.creationDate)} />
													</Grid>
												</Grid>
											</Box> 
											</Accordion>

											
										</div>
									))
								}
							</List>
							</div>
						</Grid>
						{(timeSlotList === null || timeSlotList?.length === 0) &&
							!initialState && (
								<p style={{ marginBottom: 5 }}>
									Não existe configuração para a o mês e ano selecionado.
								</p>
							)}
						</>
					)}
				</Grid>
			</Container>
		</React.Fragment>
		</Paper>
		</>}/>
		</ThemeProvider>
	);
}
