export enum PageCode {
    SIGN_UP=0,
    CHANGE_INITIAL_PASSWORD=1,
    SIGN_IN=2,
    ADD_TIME_SLOTS=3,
    LIST_TIME_SLOTS=4,
    ADD_SCHEDULING=5,
    LIST_SCHEDULINGS=6,
    SCHEDULING_DETAILS=7,
    CHANGE_SCHEDULING_DATA=8,
    ADD_NEWS=9,
    LIST_NEWS=10,
    HOME_PAGE=11,
    LIST_USERS=12,
    ACCOUNT_DETAILS=13,
    CHANGE_PASSWORD=14,
    PAGE_NOT_FOUND=15
}