const DATE_FORMAT='YYYY-MM-DD';


export const getFormattedTime = (time) => {
    const hour = time?.$H > 9 ? time?.$H : "0".concat(time?.$H);
    const minutes = time?.$m > 9 ? time?.$m : "0".concat(time?.$m);
    return `${hour}:${minutes}`;
}

export const getFormattedDate = (date) => {

    if (!date || date === '') {
        return '';
    }

    const dateParam = new Date(date);
    const month = dateParam?.getMonth() + 1;
    const year = dateParam?.getFullYear();
    const monthFormatted = month > 9 ? month : "0".concat(month);
    return `${monthFormatted}/${year}`;
}


export const getDateDayMonth = (date) => {
    if (!date || date === '') {
        return '';
    }

    const dateParam = new Date(date);
    const month = dateParam?.toLocaleString('pt-PT', { month: 'short' });
    let day = dateParam?.getDay();
    day = day > 9 ? day : '0'.concat(day);
    return `${day} ${month.split('.')[0].toUpperCase()}`;
}



export const getFormattedFullDate = (dateParam, format, isNA = false) => {
    if (!dateParam || dateParam === '') {
        return isNA ? 'N/A' : '';
    }

    const date = new Date(dateParam);
    const day = date?.getDate();
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const dayFormatted = day > 9 ? day : '0'.concat(day);
    const monthFormatted = month > 9 ? month : '0'.concat(month);

    return format === DATE_FORMAT
        ? `${year}-${monthFormatted}-${dayFormatted}`
        : `${dayFormatted}/${monthFormatted}/${year}`;
}


export const getFormattedHours = (dateParam) => {
    if (!dateParam || dateParam === '') {
        return 'N/A';
    }

    const date = new Date(dateParam);
    const hours = date?.getHours();
    const minutes = date?.getMinutes();

    const hoursFormatted = hours > 9 ? hours : '0'.concat(hours);
    const minutesFormatted = minutes > 9 ? minutes : '0'.concat(minutes);
    return `${hoursFormatted}:${minutesFormatted}`;
};


export const getFormattedDateAndHours = (dateParam) => {

    if (!dateParam || dateParam === '') {
        return 'N/A';
    }

    const date = new Date(dateParam);
    const hours = date?.getHours();
    const minutes = date?.getMinutes();
    
    const hoursFormatted = hours > 9 ? hours : "0".concat(hours);
    const minutesFormatted = minutes > 9 ? minutes : "0".concat(minutes);


    const day = date?.getDate();
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const dayFormatted = day > 9 ? day : "0".concat(day);
    const monthFormatted = month > 9 ? month : "0".concat(month);
    

    return `${dayFormatted}-${monthFormatted}-${year} às ${hoursFormatted}:${minutesFormatted}`;
}


export const getLastDayOfMonth = (dateParam) => {
    if (!dateParam || dateParam === '') {
        return '';
    }
    let date = new Date(dateParam);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return getFormattedFullDate(lastDay, DATE_FORMAT);
}


export const getCurrentDay = (date) => {
    let currentDate = new Date();
    let inputDate = new Date(date);
    if (inputDate.getDate()===currentDate.getDate() 
    && inputDate.getFullYear() === currentDate.getFullYear()) {
        inputDate = currentDate;
    } 
    return getFormattedFullDate(inputDate, DATE_FORMAT);
}

export const getFormattedFullDay = (inputDate) => {
    const days = ['Domingo','Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sábado'];
    const months = ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Augosto','Setembro','Outubro','Novembro','Dezembro'];

    const date = new Date(inputDate);

    const day = date.getDate();
    const weekday = days[ date.getDay()];
    const month = months[ date.getMonth() ];
    const year = date.getFullYear();

    return `${weekday}${', '}${day} ${month} ${year}`;
}