import { Box, Container, CssBaseline, Grid, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import pageNotFoundImg from '../../assets/images/page_not_found.png';
import { green } from '@mui/material/colors';


export default function PageNotFound() {
  const navigate = useNavigate();
  function redirectToHome(){
    navigate('/');
  };

  let theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <Container fixed>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}>
            <Grid marginTop={0}>
              <Typography variant="h4" textAlign={'center'}>
                OPS!
              </Typography>
              <img src={pageNotFoundImg} />
              <Typography variant="h4" textAlign={'center'} paddingTop={5} paddingBottom={5}>
                Página não encontrada!
              </Typography>
            </Grid>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={()=> redirectToHome()}>
              Ir à páginal inicial
            </Button>  
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
   
  )
}
