export const convertImageToBinary = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => { callback(reader.result); };
    reader.onerror = () => { console.error("convertBinaryToImage() ->  [File: " + file + "]");}
}


{/*exemple
    let 

*/}
export const convertBinaryToImage = (encodedImageBase64) => {
    try {
        let Image = ({ data }) => <img src={`data:image/jpeg;base64, ${data}`} />
        return <Image data={encodedImageBase64} /> 
    } catch (error) {
        
    }
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}


