import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button, Card, CardActions, CardContent, CardMedia, Container, FormControl, Grid, IconButton, InputLabel, Select, ThemeProvider, Toolbar, Tooltip, createTheme } from '@mui/material';
import { getFormattedDate } from '../../../utils/DateUtils';
import PageHeaderSection from '../../../components/ui/PageHeaderSection';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { HttpResponseStatus } from '../../../models/common/HttpResponseStatus';
import { useNavigate } from 'react-router';
import { green } from '@mui/material/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import UpdateNews from './UpdateNews';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import { PopupType } from '../../../utils/PopupType';
import Popup from '../../../components/ui/Popup';
import '../../../assets/css/global.module.scss';



export default function ListNews() {
  const [news, setNews]= useState([]);
  const [newsDetails, setNewsDetails]=useState({});
  const [showNewsDetails, setShowNewsDetails] = useState(false);
  const [newsId, setNewsId]= useState();
  const [edit, setEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [newsCategoryList, setNewsCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  let URL_NEWS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_NEWS}`;
  let URL_NEWS_CATEGORIES = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_NEWS_CATEGORIES}`;

  const newsFilters = '&orderColumn=creationDate&direction=DESC&pageSize=9999&v=' + `${new Date().valueOf()}`;

  useEffect(() => {
    fetchNewsCategories();
    fetchNews();
  },[]);


  const handleCancelShowDetails = () => {
    setShowNewsDetails(false);
    fetchNews();
  }

  const fetchNews = async (category) => {
    try {

      setNews([]);
			setLoading(true);
			setSelectedCategory(category);
      setNewsDetails({});
      
			let url = !category ? URL_NEWS +'?' : `${URL_NEWS}?category=${category}`;
			const response = await axiosPrivate.get(url + newsFilters);

      setNews(response.data.content);
      setLoading(false);
      
    } catch (err) {
      setLoading(false);
      
      /*Expired token then force logout*/
    if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
      localStorage.clear();
      navigate('/login');
    }
  }
}


  const getNewsDetails = async (newsId) => {
    try {

      setNewsDetails({});
      setShowNewsDetails(false);
      const response = await axiosPrivate.get(URL_NEWS + `/${newsId}?v=${new Date().valueOf()}`);
     
      if (response.status !== HttpResponseStatus.OK) {
        return Promise.reject(response?.status);
      }

      setNewsDetails(response.data.news);
      setShowNewsDetails(true);

    } catch (err) {
      
      /*Expired token then force logout*/
    if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
      localStorage.clear();
      navigate('/login');
    }
    }
  }


  const handleClick =(e)=> {
    e.preventDefault();
    setNewsId(e.target.id);
    getNewsDetails(e.target.id);
  }

  const handleDelete =(e)=> {
    e.preventDefault();
    deleteNews(newsId);
  }


  const handleEditNews = (e) => {
    e.preventDefault();
    setEdit(true);
  }


  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );


  const deleteNews = async (newsId) => {
    try {

      setSuccess(false);
      setMessage('');
      const response = await axiosPrivate.delete(URL_NEWS + `/${newsId}`);
     
      if (response.status !== HttpResponseStatus.OK) {
        return Promise.reject(response?.status);
      }

      fetchNews();
      setShowNewsDetails(false);
      setSuccess(true);
      setMessage('Notícia foi apagada com sucesso!');

    } catch (err) {
      
      /*Expired token then force logout*/
    if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
      localStorage.clear();
      navigate('/login');
    }
    }
  }


  const refreshData = (e) => {
    e?.preventDefault();
    fetchNews();
  }

  const fetchNewsCategories = async () => {

		try {
			const response = await axiosPrivate.get(`${URL_NEWS_CATEGORIES}?v=${new Date().valueOf()}`);
			setNewsCategoryList(response.data.lovs);
		} catch (err) {
			console.log("Error: ", err);
		}
	}

  const filtroChange = () => {
		const select = document.getElementById("filtro-noticia-select");
		const categoryCode = select.options[select.selectedIndex].id;
		setSelectedCategory(select.options[select.selectedIndex].text);
		fetchNews(categoryCode);
	};

  return (
    <ThemeProvider theme={theme}>
      {!edit ? 
      <Container component="main" sx={{ mb: 4 }}>

        { !showNewsDetails && <>
        <PageHeaderSection
          title='Consultar notícias'
          description='Lista de notícias publicadas no website.'
        />
        <Paper  elevation={4} component={Container}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 }
            }}
          >
            <Typography
              sx={{ flex: '1 1 100%', fontWeight: 600}}
              variant="h4"
              id="tableTitle"
              component="div"
            >
              Notícias
            </Typography>

            {news &&
            <Tooltip title="Atualizar" style={{marginRight: 10, marginTop: 10}} >
              <IconButton
                onClick={refreshData}
              >
                <CachedIcon />
            </IconButton>
            </Tooltip>
          }
          </Toolbar>
          <Paper>
            <FormControl fullWidth size='small'>
              <InputLabel
                id='filtro-noticia-label'
                variant='outlined'
                sx={{ fontSize: '1.8rem' }}
              >
                Filtros
              </InputLabel>
              <Select
                id='filtro-noticia-select'
                labelId='filtro-noticia-label'
                defaultValue={'Todas'}
                label='Filtros'
                onChange={filtroChange}
                variant='outlined'
                native
                sx={{ fontSize: '1.8rem' }}
              >
                <option>Todas</option>
                {newsCategoryList.map((item, i) => (
                  <option
                    id={item.code}
                    value={item.description}
                    key={'option-' + i}
                    style={{ fontSize: '1.8rem' }}
                  >
                    {item.description}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Paper>

      { loading? 
        <Typography variant="h6" gutterBottom paddingBottom={5} paddingTop={5} sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pt: 5 }}>
          Por favor aguarde...
        </Typography>
      
      :(!news || news?.length < 1 ? 
        <>
        <Typography 
          sx={{ flex: '1 1 100%', paddingBottom: 5, marginLeft: 2 }}
          variant="h4"
          component="div" gutterBottom marginBottom={5}>
            Sem resultados para apresentar
        </Typography>
        
        </> :
        <>
      <Container sx={{ py: 8 }}>
              <Grid container spacing={4}>
                {news.map((news) => (
                  <Grid item key={news.id} xs={12} sm={6} md={4}>
                    <Card
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                      <CardMedia
                        component="div"
                        sx={{
                          // 16:9
                          pt: '56.25%',
                        }}
                        image={news.filePath ? `${process.env.REACT_APP_DOMAIN}${news.filePath}` : ''}
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2" sx={{fontWeight: 600}}>
                          {news?.title?.length > 20 ? news?.title?.substring(0, 19)?.concat('...') : news.title}
                        </Typography>
                        <Typography>
                          {news?.message?.length > 27 ? news?.message?.substring(0, 26)?.concat('...') : news.message}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small">{getFormattedDate(news.creationDate)}</Button>
                        <Button size="small" id={news.id} onClick={handleClick} sx={{textTransform: 'none'}}>Ver detalhes</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
      </>)}
        </Paper>
        </>
}
 
 { showNewsDetails &&
<>
        <PageHeaderSection
          title='Detalhes de notícias'
          description='Lista de notícias publicadas no website.'
        />
        <Paper  elevation={4} component={Container}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 }
            }}
          >
            <Typography
              sx={{ flex: '1 1 100%', fontWeight: 600}}
              variant="h4"
              id="tableTitle"
              component="div"
            >
              Detalhes da notícia
            </Typography>

            <Tooltip title="Eliminar notícia" style={{marginRight: 10, marginTop: 10}} >
              <IconButton
                onClick={handleDelete}
              >
                <DeleteIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Editar notícia" style={{marginRight: 10, marginTop: 10}} >
              <IconButton
                onClick={handleEditNews}
              >
                <EditIcon />
            </IconButton>
            </Tooltip>
          </Toolbar>
      { 
        <>
          <Container sx={{ py: 8 }}>
            <Grid item xs={12} minHeight={200} marginBottom={2}>
              <Card
               raised
               sx={{
                 maxWidth: '50%',
                 margin: "0 auto",
                 padding: "0.1em",
                 border: "none", 
                 boxShadow: "none"
               }}
              >
                <CardMedia
                  component="img"
                  width={800}
                  height={500}
                  image={newsDetails.filePath ? `${process.env.REACT_APP_DOMAIN}${newsDetails.filePath}` : ''}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
              </Card>
              <Typography variant="h5" sx={{mt: 2, mb: 5, flexGrow: 1, fontWeight: 600, textAlign: 'center' }} gutterBottom>
                {newsDetails.title}
              </Typography>
              <Box
                component="main"
                sx={{ flexGrow: 1, wordWrap: 'break-word' }}
              >
                <Typography paragraph sx={{textAlign:'justify', textJustify:'inter-word', whiteSpace: 'pre-wrap'}}>
                  {newsDetails.message}
                </Typography>
              </Box>
            </Grid>
          </Container>
        </>
      }
    </Paper>
    <Box
      component="span"
      marginTop={5}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      paddingLeft={0}
    >
      <Button 
        variant="outlined"
        type='button' 
        sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
        size='medium'
        onClick={handleCancelShowDetails}
        startIcon={<ArrowBackIcon />}
        >
        Voltar
      </Button>
    </Box>
  </>

 }
        { success &&
						<Popup 
							type={PopupType.SUCCESS}  
							content={message}
							flag={success}
							duration={10000}
						/>
					}
      </Container>
        :
        <UpdateNews 
          setsuccess={setSuccess}
          setmessage={setMessage}
          setedit={setEdit}
          setshowdetails={setShowNewsDetails}
          news={newsDetails} 
          setnewsdetails={setNewsDetails}
        />
      }
    </ThemeProvider>
  );
}
