import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography, Button, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimeField } from '@mui/x-date-pickers';
import Loading from '../../components/ui/Loading';
import { Constants } from '../../utils/Constants';
import {HttpResponseStatus} from '../../models/common/HttpResponseStatus';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import {Container} from '@mui/material';
import PageHeaderSection from '../../components/ui/PageHeaderSection';

import { getFormattedTime } from '../../utils/DateUtils';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Popup from '../../components/ui/Popup';
import { PopupType } from '../../utils/PopupType';
import { useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import labels from '../../utils/Labels.json';
import { extractErrorMessagesFromResponse } from '../../utils/ResponseMessageUtils';
import CustomContainer from '../../components/ui/CustomContainer';

export default function AddTimeSlots() {
  const [beginSchedulingDate, setBeginSchedulingDate] = useState(null);
  const [endSchedulingDate, setEndSchedulingDate] = useState(null);
  const [beginWorkTime, setBeginWorkTime] = useState(null);
  const [endWorkTime, setEndWorkTime] = useState(null);
  const [beginLunchTime, setBeginLunchTime] = useState(null);
  const [endLunchTime, setEndLunchTime] = useState(null);
  const [serviceInterval, setServiceInterval] = useState('');
  const [availableCollaboratorNumber, setAvailableCollaboratorNumber] = useState('');
  
  const [configurationType, setConfigurationType] = useState('');






  const [time, setTime] = useState('');
  const [error, setError] = useState(false);

  const validateTime = (inputTime) => {
    // Add your custom validation logic here
    // For example, you can check if the time is in a specific format or within a valid range.
    const isValidTime = true;
    return isValidTime;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setTime(inputValue);

    // Validate the time
    const isValid = validateTime(inputValue);

    // Update the error state based on validation result
    setError(!isValid);
  };

  const axiosPrivate = useAxiosPrivate();
  
  const [validForm, setValidForm]= useState(false);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showEndDate, setShowEndDate] = useState(false);
  
  const current = new Date();
  const nextDay = new Date(current.getTime() + Constants.DAY_IN_MILISSECONDS); // + 1 day in ms
  const nextDayPlusOne = new Date(current.getTime() + (Constants.DAY_IN_MILISSECONDS * 2)); // + 2 day in ms

  const URL_TIME_SLOTS = `${process.env.REACT_APP_URL_BASE}${process.env.REACT_APP_ROUTE_TIME_SLOTS}`;

     const addTimeSlots = async (data) => {
      try {

        setLoading(true);
        setIsSuccess(false);
        setIsError(false);

        const response = await axiosPrivate.post(URL_TIME_SLOTS, JSON.stringify(data));
        if (response.status !== HttpResponseStatus.OK) {
          return handleError(response);
        }
        setLoading(false);
        setError(false);
        setErrorMessage('');
        setIsSuccess(true);
        clearForm();

      } catch (err) {
        setLoading(false);
        /*Expired token then force logout*/
      if (err?.response?.status === HttpResponseStatus.UNAUTHORIZED) {
        localStorage.clear();
        navigate('/login');
      } else {
        console.log("err: ", err);
          handleError(err?.response);
          setStat({ open: true });
        }
      }
    }


     const clearForm = () => {
      setBeginSchedulingDate(null);
      setEndSchedulingDate(null);
      setBeginWorkTime(null);
      setEndWorkTime(null);
      setBeginLunchTime(null);
      setEndLunchTime(null);
      setServiceInterval('');
      setAvailableCollaboratorNumber('');
      setConfigurationType('');
     }

  const handleError = (response) => {
    setLoading(false);
    setIsError(true);
    setStat({ open: true });
    if (!response || response.status === HttpResponseStatus.NOT_FOUND) {
      setErrorMessage(labels.common_generic_error);
      return;
    }

    const errors = extractErrorMessagesFromResponse(response);
    setErrorMessage(errors?.join('; '));

    return;
  }



  const handleConfigurationType = (event) => {
    event?.preventDefault();
    const selectedType = event.target.value;
    setConfigurationType(selectedType);
    setShowEndDate(selectedType === 1);
  }

  const handleBeginSchedulingDate = (date) => {
    setBeginSchedulingDate(date?.format('YYYY-MM-DD'));
  }

  const handleEndSchedulingDate = (date) => {
    setEndSchedulingDate(date?.format('YYYY-MM-DD'));
  }

  const handleAvailableCollaboratorNumber = (e) =>{
    setAvailableCollaboratorNumber(e.target.value);
  }

   const handleServiceInterval = (e) => {
    setServiceInterval(e.target.value);
   }

  const handleSubmit = (event) => {
    event?.preventDefault();
    
    const request = {
      beginSchedulingDate: beginSchedulingDate,
      beginWorkTime: beginWorkTime ? getFormattedTime(beginWorkTime) : '',
      endWorkTime: endWorkTime ? getFormattedTime(endWorkTime) : '',
      beginLunchTime: beginLunchTime ? getFormattedTime(beginLunchTime) : '',
      endLunchTime: endLunchTime ? getFormattedTime(endLunchTime) : '',
      serviceInterval: serviceInterval,
      availableCollaboratorNumber: availableCollaboratorNumber
    }

    if (showEndDate) {
      request.endSchedulingDate= endSchedulingDate;
    }

    addTimeSlots(request);
  };


  const enableButton = () => {
    
    let validDates = showEndDate ? beginSchedulingDate !== null && endSchedulingDate !== null : beginSchedulingDate !== null;

    const check = validDates &&
            validHourFormat(beginWorkTime)&& 
            validHourFormat(endWorkTime) && 
            validHourFormat(beginLunchTime)&& 
            validHourFormat(endLunchTime) &&
            configurationType &&
            availableCollaboratorNumber &&
            availableCollaboratorNumber.trim() !==''&&
            serviceInterval;

    setValidForm(check || check===0);
  }

  const validHourFormat = (time) => {
    return !isNaN(time?.$H) && !isNaN(time?.$m);
  }

  const soltsAtendimento = [
                            {id: 0, duration: 15},
                            {id: 1, duration: 20},
                            {id: 2, duration: 25},
                            {id: 3, duration: 30}
                          ]

  const [stat, setStat] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  useEffect(()=> {
    enableButton();
  }, [beginSchedulingDate, endSchedulingDate, 
      beginWorkTime, endWorkTime, 
      beginLunchTime, endLunchTime, 
      serviceInterval, availableCollaboratorNumber, configurationType])

  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#506E58',
        },
        secondary: {
          main: green[500],
        },
      },
    }
  );


  return (
    <ThemeProvider theme={theme}>
      { loading &&<Loading />}
      <CustomContainer 
      content={
        <>
          <PageHeaderSection 
            title='Criar faixas horárias'
            description='Criação de faixas horárias para o serviço de agendamentos.'
          />
            <Paper elevation={2} component={Container}>
              <Typography
                variant="h4"
                component="div"
                fontWeight={'600'}
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  pt: 5
                }}
              >
                Dados de faixa horária
              </Typography>
              
              <Grid container spacing={3} marginTop={1} sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="configurationType-label">Tipo de configuração</InputLabel>
                    <Select
                      required
                      labelId="configurationType-label"
                      id="configurationType"
                      label="Tipo de configuração"
                      onChange={handleConfigurationType}
                      defaultValue={''}
                      value={configurationType}
                    >
                      <MenuItem value={0} key={0}>Única</MenuItem>
                      <MenuItem value={1} key={1}>Intervalo de datas</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="nCollaborators"
                    name="nCollaborators"
                    label="Nº de colaboradores"
                    fullWidth
                    autoComplete="collaborators"
                    variant="standard"
                    onChange={handleAvailableCollaboratorNumber}
                    value={availableCollaboratorNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <DatePicker
                      required
                      minDate={dayjs(nextDay)}
                      defaultValue={null} 
                      disablePast 
                      label="Data início"
                      slotProps={{ textField: { fullWidth: true, variant:'standard' } }}
                      onChange={handleBeginSchedulingDate}
                      value={beginSchedulingDate}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth sx={{minWidth: 120}}>
                    <InputLabel id="intervalType-label">Intervalo de atendimento (minutos)</InputLabel>
                    <Select
                      required
                      labelId="intervalType-label"
                      id="intervalType"
                      label="Intervalo de atendimento (minutos)"
                      onChange={handleServiceInterval}
                      defaultValue={''}
                      value={serviceInterval}
                    >
                      { soltsAtendimento.map((slot, key) => (
                          <MenuItem value={slot.duration} key={key}>{slot.duration}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                { showEndDate &&
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <DatePicker
                      minDate={dayjs(nextDayPlusOne)}
                      defaultValue={null}
                      disablePast 
                      label="Data fim"
                      slotProps={{ textField: { fullWidth: true, variant:'standard' } }}
                      onChange={handleEndSchedulingDate}
                      value={endSchedulingDate}
                    />
                  </LocalizationProvider>
                </Grid>
                }
              </Grid>

              <Typography variant="h6" sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, marginTop: 10 }}>Dados do funcionamento Consular</Typography>
              <Grid container spacing={3} marginTop={1} marginBottom={5} sx={{pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pb: 10}}>
                <Grid item xs={12} sm={6} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <TimeField
                      required
                      label="Hora início trabalho"
                      id="horaInicioTrabalho"
                      name="horaInicioTrabalho"
                      format="HH:mm"
                      fullWidth
                      variant="standard"
                      onChange={(newValue) => setBeginWorkTime(newValue)}
                      value={beginWorkTime}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <TimeField
                      required
                      label="Hora fim trabalho"
                      id="horaFimTrabalho"
                      name="horaFimTrabalho"
                      format="HH:mm"
                      fullWidth
                      variant="standard"
                      onChange={(newValue)=> setEndWorkTime(newValue)}
                      value={endWorkTime}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={6} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <TimeField
                      required
                      label="Hora início almoço"
                      id="horaInicioAlmoco"
                      name="horaInicioAlmoco"
                      format="HH:mm"
                      fullWidth
                      variant="standard"
                      onChange={(newValue)=> setBeginLunchTime(newValue)}
                      value={beginLunchTime}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
                    <TimeField
                      required
                      label="Hora fim almoço"
                      id="horaFimAlmoco"
                      name="horaFimAlmoco"
                      format="HH:mm"
                      fullWidth
                      variant="standard"
                      onChange={(newValue)=> setEndLunchTime(newValue)}
                      value={endLunchTime}
                    />
                  </LocalizationProvider>
                </Grid>    
              </Grid>
            </Paper>

          <Box
            component="span"
            marginTop={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            paddingLeft={0}
          >
            <Button 
              variant="contained"
              type='submit' 
              sx={{ height: 40, textTransform: 'none' , fontSize: '18px'}}
              disabled={!validForm || loading}
              endIcon={<ArrowForwardIcon />}
              onClick={() => handleSubmit()}
            >
              Criar faixa horária
            </Button>
          </Box>

          { isError &&
            <Popup 
              type={PopupType.ERROR}  
              content={errorMessage}
              flag={isError}
              duration={5000}
            />
          }
          {
            isSuccess &&
            <Popup 
              type={PopupType.SUCCESS}  
              content={'Faixas horárias foram configuradas com sucesso!'}
              flag={isSuccess}
              duration={5000}
            />
          }
        </>
      } 
    />
  </ThemeProvider>
  )
}